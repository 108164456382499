import React, { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getListingById } from '../../../Apis/Api';
const PropertyContext = createContext();
export const PropertyProvider = ({ children }) => {
    const params = useParams()
    const locationId = params.id
    const [propertyData, setPropertyData] = useState(null);
    const fetchPropertyData = async () => {
        try{
            const resp = await getListingById(locationId)
            setPropertyData(resp?.property)
        }catch(error){
            console.log(error)
        }
    };

    return (
        <PropertyContext.Provider value={{propertyData, fetchPropertyData }}>
        {children}
        </PropertyContext.Provider>
    );
};

export const useProperty = () => {
  return useContext(PropertyContext);
};