import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import '../Home/Home.css'
import './RecentEvents.css'
import Header from "../../../Components/Header/Header"
import Footer from "../../../Components/Footer/Footer"
import SearchableSelect from "../../../Inputs/Select"
import Enquiry from "../../../Modals/Enquiry"
import { Pagination, Stack } from "@mui/material";
import { getDashRecentEvents, addRemoveRecentEvents, deleteFilesFromBucket } from "../../../Apis/Api"
import ToolTip from "../../../Utils/ToolTip"
import DatesFilter from "../../../Modals/DatesFilter"
import addIcon from "../../../Assets/Images/Dashboard/Vector.svg";
import RecentEventsComponent from "../../../Components/RecentEvents/RecentEvents"
import RecentEventsModal from "../../../Modals/RecentEventsModal";
import { useAuth } from "../../../Utils/Auth";
import { Delete } from "@mui/icons-material"
import moment from 'moment';

const headers = ["NAME", "DATE", "TYPE", "IMAGE","ACTION"]
const noOfdata = 20;
const RecentEvents = () => {
    const [activeTab,setActiveTab] = useState('Events');
    const [listings,setListings] = useState([]);
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const [data,setData] = useState([]);
    const [init,setInit] = useState(false);
    const [isNoData,setIsNoData] = useState(false);
    const [detailsShow,setDetailsShow] = useState(false);
    const [details,setDetails] = useState({});
    const [showFilter, setShowFilter] = useState(false);
    const {confirm, closeConfirmToast} = useAuth();
    const [showModal, setShowModal] = useState(false);
    const {notify} = useAuth();
    const location = useLocation();
    const locationId = location?.state?.locationId;
    const [formData, setFormData] = useState({
        enquiry : '',
        listing : locationId || '',
        startDate : '',
        endDate : '',
    })
    const toggleDetails = (data) => {
        if(data) setDetails(data)
        setDetailsShow(!detailsShow)
    }
    const onChangeOption = (name,value) => {
        setFormData({
            ...formData,
            [name] : value,
        })
        setPage(1)
    };
    const onPageChange = (event, page) => {
        const scrollEl = document.getElementById("dash_home_main_container")
        if(scrollEl) scrollEl.scrollIntoView({ behavior: 'smooth' });
        setPage(page <= 0 ? 1 : page);
    };
    const getData = async () => {
        try{
            const resp = await getDashRecentEvents({page,noOfdata,...formData});
            setData(resp?.data?.data);
            if(resp?.data?.data?.length === 0){
                setIsNoData(true);
            }else{
                setIsNoData(false);
            }
            if(!init){
                setInit(true)
                setListings(resp?.data?.userListings);
            }
            setPagesCount(resp?.data?.pagesCount);
        }catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        getData()
    },[page,formData]);

    const onDeleteEvent = async(locationId, id, image) => {
        try{
            if (image) await deleteFilesFromBucket([image]);
            const response = await addRemoveRecentEvents({locationId, id});
            notify(response?.data?.message, "success");
            getData();
        } catch(error) {
            console.log(error?.response?.data?.message);
            notify(error?.response?.data?.message, "error");
        }
    }

    const onClickDelete = (locationId, id, image) => {
        closeConfirmToast();
        confirm("Are you sure you want to delete this event ?", "Delete", () => onDeleteEvent(locationId, id, image) )
    }

    return (
        <div className='Spotlet'>
            <Header/>
            <div className='dash_home_main_container' id='dash_home_main_container'>
                <span className="main_text heading_color_1 justify-content-start2">Recent Events</span>
                <div className="dash_home_wrap_container mt-2">
                    { (init) && (
                            <div className="dash_home_header">
                                {/* <div className="dash_home_enquiry_wrap_section cursor">
                                    <div className={activeTab === 'Events' ? 'dash_home_enquiry_text_section_active' : 'dash_home_enquiry_text_section'}>
                                        onClick={()=>handleTab('Enquiries')} 
                                        <span className="cotent">Events</span>
                                    </div>
                                </div> */}
                                <div className="dash_event_header_right_wrapper">
                                    <div className="dash_home_input_wrapper">
                                        <span className="content text_color_1 mt-1 dash_home_input_label">Listings : </span>
                                        <div className="dash_home_header_right_wrapper_input">
                                            <SearchableSelect
                                                name='listing'
                                                options={listings}
                                                value={formData?.listing || ""}
                                                onChange={onChangeOption}
                                                placeholder='All listings'
                                                inputStyle={{boxShadow : '0px 4px 44px 0px #0000001A'}}
                                                style={{zIndex : '3'}}
                                            />
                                        </div>
                                    </div>
                                    {/* <button className="btn btn-primary sp_button dash_home_filter_button" type="button" onClick={() => {setShowFilter(true)}}> Filter </button> */}
                                </div>
                            </div>
                        )
                    }
                    {(init && isNoData) ? (
                        <div className="d-flex flex-row justify-content-center align-items-center mt-2 w-100 h-100">
                            <RecentEventsComponent props = {{ listings : listings?.length > 0 ? [...listings] : [], onSuccess : () => getData() }} />
                        </div>
                    ) : (
                        <>
                            <table className=" main_text text-center mt-2">
                                <thead>
                                    <tr className=" heading_color_2">
                                    {headers.map((header, index) => (
                                        <th key={index} className="content  ">{header}</th>
                                    ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, index) => (
                                    <tr key={index}>
                                        <td className="content text_color_1">{row?.name} <br/> ({row?.locationId}) </td>
                                        <td className="content text_color_1">{moment(row?.date).format('DD-MMM-YYYY')}</td>
                                        <td className="content text_color_1">{row?.type}</td>
                                        <td className="content text_color_1"><img loading="lazy" src = {row?.image} alt = "event-image" className="dashboard_recent_events_image" /></td>
                                        <td>
                                            <div className="d-flex flex-row justify-content-center align-items-center">
                                                <button className="btn btn-primary sp_button dashboard_delete_button" onClick={() => onClickDelete(row?.locationId, row?.id, row?.image)}>Delete</button>
                                                <button className="btn btn-primary sp_button dashboard_delete_icon" onClick={() => onClickDelete(row?.locationId, row?.id, row?.image)}><Delete/></button>
                                            </div>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="btn-primary sp_button dashboard_event_add_button" type="button" onClick={() => setShowModal(true)}><img className="m-1" src={addIcon} alt="add icon"/> Add New</button>
                        </>
                    )}
                </div>
                {!(init && isNoData) && (
                    <div className="d-flex flex-row justify-content-center align-items-center mt-2 mb-3 w-100">
                        <Stack spacing={2}>
                            <Pagination 
                                onChange={(event, page) => onPageChange(event, page)}  
                                count={pagesCount}
                                variant="outlined"
                                shape="rounded"
                                page = {page}
                                // siblingCount={0}
                                // boundaryCount={1}
                            />
                        </Stack>
                    </div>
                )}
            </div>
            <Footer/>
            {detailsShow && <Enquiry show={detailsShow} onHide={toggleDetails} data={details}/>}
            {showFilter && (
                <DatesFilter
                    show={showFilter}
                    onHide={() => setShowFilter(false)}
                    onSuccess={(data) => { 
                        setFormData({
                            ...formData,
                            startDate : data?.startDate || "",
                            endDate : data?.endDate || "",
                        })
                    }}
                    title = "Filter"
                    data = {{
                        startDate : formData?.startDate || "",
                        endDate : formData?.endDate || "",
                    }}
                />
            )}
            {showModal && (
                <RecentEventsModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    onSuccess={() => { 
                        setShowModal(false);
                        getData();
                    }}
                    title = "Event"
                    listings = {listings?.length > 0 ? [...listings] : []}
                    backdrop="static"
                />
            )}
        </div>
    )
}
export default React.memo(RecentEvents);