import axios from "./Interceptor"

const baseUrl = process.env.REACT_APP_API_URL;

export const getCities = (event) => {
    return axios.get(`${baseUrl}/allCities/${event}`)
}

export const getFeaturedAndExclusiveProperties = async(type) => {
    return axios.get(`${baseUrl}/featuredAndExclusiveProperties/${type}`)
}

export const getRecentlyAddedProperties = async() => {
    return axios.get(`${baseUrl}/recentlyAddedProperties`)
}

export const getClientTestimonials = async() => {
    return axios.get(`${baseUrl}/clientTestimonials`)
}

export const sendRegisterOtp = (data) => {
    return axios.post(`${baseUrl}/sp_send_register_otp`, data);
};
// for sending login otp
export const sendLoginOtp = (data) => {
    return axios.post(`${baseUrl}/sp_send_login_otp`, data);
};
export const registerUser = (data) => {
    return axios.post(`${baseUrl}/sp_register`, data);
};
export const registerGoogleUser = (data) => {
    return axios.post(`${baseUrl}/sp_google_register`, data);
};
export const loginUser = (data) => {
  console.log("data", data)
    return axios.post(`${baseUrl}/sp_login`, data);
};
export const sendForgotOtp = (data) => {
    return axios.post(`${baseUrl}/sp_send_forgot_otp`, data);
};
export const resetUserPassword = (data) => {
    return axios.post(`${baseUrl}/sp_reset_password`, data);
};
export const resendOtp = (data) => {
    return axios.post(`${baseUrl}/sp_resend_otp`, data);
};

// For Search Page
export const getSearchListings = (data, page, noOfLocations) => {
    return axios.post(
      `${baseUrl}/searchListings`,
      { data, page, noOfLocations }
    );
};
export const getSearchDropData = (data) => {
  return axios.get(`${baseUrl}/get_search_drop_list/${data}`)
}
export const sendNewsLetterMail = (email) => {
    const token = localStorage.getItem("auth");
    return axios.post(`${baseUrl}/newsLetter`, {email}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

// send host get in touch mail
export const sendHostGetInTouch = (data) => {
    return axios.post(`${baseUrl}/hostgetintouch`, data, {});
  };
  
//getNewFavourites
// Using multiple times in src -->  Pages --> Favorites 11
export const getNewFavouritesList = (sort, value) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/sp_user_favouites`, { value, sort }, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
//Send Apply now details
export const getJobs = (page, offSet, searchTerm) => {
    return axios.get(`${baseUrl}/jobs/?page=${page}&items_per_page=${offSet}&searchTerm=${searchTerm}`);
  };
  

export const applyJob = (formData, selectedJob, userId) => {
    return axios.post(`${baseUrl}/jobs/apply`, { data: formData, jobId: selectedJob, userId: userId });
};

export const uploadResume = (formData) => {
    return axios.put(`${baseUrl}/jobs`, formData, {
        headers: {
        "Content-Type": "multipart/form-data",
        },
    });
};
export const findMySpotEnquiry = (data) => {
    return axios.post(`${baseUrl}/sp_findmyspot`, data);
};
export const eventplanning = (data) => {
    return axios.post(`${baseUrl}/sp_eventplanning`, data);
};
export const getUserData = (token) => {
    return axios.get(`${baseUrl}/sp_user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
};
export const getPropertyTypes = () => {
    const token = localStorage.getItem("auth");
    return axios.get(`${baseUrl}/sp_property_types`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
};
export const updateUser = (data) => {
    const token = localStorage.getItem('auth')
    return axios.put(`${baseUrl}/sp_user`, data ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
};
export const uploadPics = (formData) => {
    const token = localStorage.getItem("auth");
    return axios.post(`${baseUrl}/sp_user/upload-pic`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
};
export const updateUserPassword = (data) => {
    const token = localStorage.getItem('auth')
    return axios.put(`${baseUrl}/sp_user_change_pass`, data ,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
};
export const getListingData = (formData) => {
  const token = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/sp_listing_data`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const createIncompleteListing = (data) => {
  const token = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/sp_incomplete`, {...data}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateListing = (data) => {
  const token = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/sp_update_listing`, {...data}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// 3rd party api for getting cities based on country and city
export const getAllCities = (data) => {
  return axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`,data);
};
export const updateFavourites = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/sp_favourites/`, {id}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};


//get amenities list
export const getAllAmenities = async () => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseUrl}/sp_amenities`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};

// for getting notifications count from services 
export const noOfUserNotifications = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseUrl}/notifications/count/${id}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

export const getUserNotifications = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseUrl}/getUserNotifications/${id}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}

// for notifications page start
export const getNotifications = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.get(
    `${baseUrl}/getnotifications/${data?.id}/?type=${data?.type}&search=${data?.search}&sort=${data?.sort}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}
export const markNotificationAsRead = (data) => {
  const auth = localStorage.getItem("auth");

  return axios.put(
    `${baseUrl}/marknotification`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}
export const deleteNotification = (data) => {
  const auth = localStorage.getItem("auth");

  return axios.put(
    `${baseUrl}/deleteNotification`, data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
}
export const getListingById = async (id) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.get(`${baseUrl}/sp_property/${id}`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};
// for notifications page end 

// to save find my spot data home page
export const saveFindMySpotData = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseUrl}/findmyspot`,
     data,
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
  );
}
export const sendGetInTouch = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/sp_send_get_in_touch`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// to get enquiry data 
export const getEnquiryData = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(
    `${baseUrl}/getEnquiry`, {...data},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    } 
  );
}
// to get user requested leads
export const getUserLeads = (data) => {
  const auth = localStorage.getItem("auth");
  const response = axios.post( `${baseUrl}/getUserLeads`, {...data} ,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response
};
export const getSimilarListings = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/getsimilarlistings`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
// for listing gallery section start 
export const uploadLocationPics = async (formData) => {
  const auth = localStorage.getItem("auth");
  const response = await axios.post(baseUrl + "/uploadlocpic", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${auth}`,
    },
  });
  return response.data;
};
export const deleteFilesFromBucket = (fileUrls) => {
  const auth = localStorage.getItem("auth");
  return axios.delete(baseUrl + `/deletefiles`, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
      data: {fileUrls},
  });
}
// for listing gallery section end
export const sendBookingRequest = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/sp_send_booking_request`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};
export const saveEnquiryData = (type, data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseUrl}/saveEnquiry/${type}`,
     data,
     {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }
     );
}

// for listing activities section start
export const getAllTags = (data) => {
  const auth = localStorage.getItem("auth");
  const response = axios.post(`${baseUrl}/allTags`, {...data} ,
  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
export const createTag = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/tag`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
  });
};
// for listing activities section end

// for listing Creation start
export const createListing = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/sp_create`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
  });
};
// for listing Creation end
export const getListings = (data) => {
  const auth = localStorage.getItem("auth")
  return axios.post(`${baseUrl}/sp_listing`,data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const listingDelete = (id) => {
  const auth = localStorage.getItem("auth");
  return axios.delete(baseUrl + `/sp_listing_delete/${id}`, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
  });
}
export const sendClickLead = (id,userId) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/leads/${id}/${userId}`, {}, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

// to get plan Amounts 
export const getPlans = () => {
  const auth = localStorage.getItem("auth");
  return axios.get(`${baseUrl}/plans`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

export const createPaymentOrder = (form) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/payment/orders`, form, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const saveSubscription = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/payment/subscription/save`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const getCoupons = (status, plan,userId, currentSubscription) => {
  const auth = localStorage.getItem("auth"); 
  const region = localStorage.getItem('regionId');
  var url = `${baseUrl}/coupons` 
  return axios.post(url, {status, plan,userId, region, currentSubscription},  {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const applyCouponCode = async (couponId, plan) => {
  const auth = localStorage.getItem("auth");
  return axios.post(baseUrl + "/coupon/apply", {couponId, plan}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

export const getSubscription = (id) => {
  const auth = localStorage.getItem("auth");
  const response = axios.post(`${baseUrl}/subscription`, {"listingId" : id}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
}
export const getDashHome = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/get_dash_home`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

export const deActivateUser = () => {
  const auth = localStorage.getItem("auth");
  return axios.post(
    `${baseUrl}/sp_user_deactivate`,
    {},
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
};


//subscribe/unsubscribe newsletter 
export const updateNewsLetter = (id, status) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/newsLetter`, {id, status}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
};

export const getDashCalendar = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/get_dash_calendar`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const getDashCalendarByListing = (id,date) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/get_dash_calendar_id/${id}`,{id,date}, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};
export const updatePricing = (listing,data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/update_pricing/${listing}`,data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
};

//subscribe/unsubscribe newsletter 
export const updateUserStatus = (data) => {
  return axios.put(`${baseUrl}/sp_update_user`, data);
};

//get meta details
export const getPageDetails = (title) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/cms`, {title}, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const blockDay = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/block_date`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}
export const updatePrice = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/update_price`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
}

// for dashboard recent events
export const getDashRecentEvents = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.post(`${baseUrl}/get_dash_recent`, data, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    });
}; 

//add or remove Recent Events
export const addRemoveRecentEvents = (data) => {
  const auth = localStorage.getItem("auth");
  return axios.put(`${baseUrl}/modify_recent_events`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  )
}