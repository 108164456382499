import { useState } from "react";
import TextInput from "../../Inputs/TextInput";
import SearchableSelect from "../../Inputs/Select";
import DateInput from "../../Inputs/DateInput";
import fileUploadIcon from "../../Assets/Images/Dashboard/RecentEvents/gallery.svg";
import { uploadLocationPics, addRemoveRecentEvents } from "../../Apis/Api";
import { Delete } from "@mui/icons-material";
import ToolTip from "../../Utils/ToolTip";
import { useAuth } from "../../Utils/Auth";
import "./RecentEvents.css";

const EVENT_TYPE = [
    { label: "Film Shootings", value: "Film Shootings" },
    { label: "Tv Shootings", value: "Tv Shootings" },
    { label: "Events", value: "Events" },
    { label: "Staycations", value: "Staycations" }
];

const FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"];

function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], Date.now() + filename, { type: mimeType });
      });
}

const RecentEventsComponent = ({props}) => {

    const {listings, onSuccess} = props;
    const [formData,setFormData] = useState({
        listing : props?.listing || "",
    })
    const [errors, setErrors] = useState({});
    const {notify} = useAuth();

    // for image showing
    const [imageFile, setImageFile] = useState(null); // For storing the actual file

    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }else{
            setErrors({...errors,[name] : `This field is required`})
        }
    };
    const onChangeOption = (name,value) => {
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }else{
            setErrors({...errors,[name] : `This field is required`})
        }
    };
    const validateName = (name) => {
        const regex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
        const trimmedName = name.trim();  // Removes leading/trailing spaces
        if (!trimmedName) {
            return "Name is required";
        } else if (!regex.test(trimmedName)) {
            return 'Name can only contain letters and spaces';
        } else if (trimmedName.length < 2) {
            return 'Name should be minimum 2 letters';
        }
        return '';
    };
    const validateForm = () => {
        const newErrors = {
            name: formData.name ? validateName(formData?.name) : 'Name is required' ,
            listing: formData.listing ? "" : 'Listing is required',
            date: formData.date ? "" : 'Date is required',
            type: formData.type ? '' : 'Type of event is required',
            image : formData.image ? '' : 'Image is required',
        };
        setErrors(newErrors);
    
        // Return false if there are errors
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                // const inputElement = document.getElementById('dashboard_'+ key);
                // if (inputElement) {
                //     inputElement.scrollIntoView({ behavior: 'smooth' });
                // }
                return false
            }
        }
        return true
    }

    const onUploadImage = async (listingId) => {
        const file = await urltoFile(
            URL.createObjectURL(imageFile),
            imageFile.name,
            "text/plain"
        );
        const mainImages = new FormData();
        mainImages.append("pic", file);
        mainImages.append("fileName", imageFile.name);
        mainImages.append("locationId", listingId);
        mainImages.append("type",'events');
        try{
            const response = await uploadLocationPics(mainImages);
            setFormData({
                ...formData,
                image : response.url,
            })
            return {...formData, image : response.url}
        }catch(error){
            console.log(error);
            notify(error?.response?.data?.message, "error");
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        if (!validateForm()) return null;
        try{
            let reqFormData = {...formData}
            if (imageFile) {
                reqFormData = await onUploadImage(formData?.listing);
            } else {
                notify("Image file not found", "error");
                return null
            }
            const response = await addRemoveRecentEvents({locationId : reqFormData?.listing , formData : {...reqFormData} })
            notify(response?.data?.message, "success");
            onSuccess();
        } catch (error) {
            console.log(error?.response?.data?.message);
            notify(error?.response?.data?.message, "error");
        }
    }

    const onClickDelete = () => {
        setFormData({
            ...formData,
            image : "",
        })
    }

    // Handle file selection and create a preview URL
    const handleImageChange = async(e, listingId) => {
        if (!listingId) {
            notify("Please select listing first", "error");
            e.target.value = null;
            return null;
        }

        const file = e.target.files[0];

        if (!file) {
            e.target.value = null;
            notify(`No file detected`, "error");
            return null;
        }

        if (!FILE_TYPES.includes(file.type)) {
            e.target.value = null;
            notify(`Only JPEG, JPG and PNG files allowed for upload.`, "error");
            return null;
        }

        const imageUrl = URL.createObjectURL(file); // Create URL for the image
        setImageFile(file); // Store the file for uploading later
        setFormData({
            ...formData,
            image : imageUrl,
        })
        e.target.value = null;
    };

    return(
        <form onSubmit={onSubmit} className="dashboad_recent_events_container row" >
            <div className='home_fms_section col-lg-6 mt-2'>
                <TextInput
                    id='dashboard_name'
                    label='Name'
                    name='name'
                    value={formData?.name || ""}
                    onChange={onChange}
                    placeholder='Enter name'
                    validate={(value) => (value ? '' : 'Name is required')}
                    errorMessage={errors?.name}
                    required={true}
                    title="Please enter event name"
                />
            </div>
            <div className='home_fms_section col-lg-6 mt-2'>
                <SearchableSelect
                    id='dashboard_listing'
                    label='Listings'
                    name='listing'
                    options={listings}
                    value={formData?.listing || ""}
                    onChange={onChangeOption}
                    placeholder='Select Listing'
                    validate={(value) => (value ? '' : 'Location is required')}
                    errorMessage={errors?.listing}
                    required={true}
                    title="Select listing"
                    style={{zIndex : '2'}}
                />
            </div>
            <div className='home_fms_section col-lg-6 mt-2'>
                <DateInput
                    id='dashboard_date'
                    label='Date'
                    name='date'
                    value={formData?.date || ""}
                    onChange={onChange}
                    placeholder='dd-mm-yyyy'
                    required={true}
                    validate={(value) => (value ? '' : 'Date is required')}
                    max={new Date().toISOString().split('T')[0]}
                    errorMessage={errors?.date}
                    title="Select date"
                />
            </div>
            <div className='home_fms_section col-lg-6 mt-2'>
                <SearchableSelect
                    id='dashboard_type'
                    label='Type'
                    name='type'
                    options={EVENT_TYPE}
                    value={formData?.type || ""}
                    onChange={onChangeOption}
                    placeholder='Select Event'
                    validate={(value) => (value ? '' : 'Type of event is required')}
                    errorMessage={errors?.type}
                    required={true}
                    title="Select type of event"
                    style={{zIndex : '1'}}
                />
            </div>
            {formData?.image ? (
                <div className="col-12 mt-2 d-flex flex-row align-items-start">
                    <img alt = "event-image" loading="lazy" src = {formData?.image} className="dashboard_recent_gallery_image"/>
                    <span className="dashboard_recent_gallery_image_delete" onClick={onClickDelete}> <Delete/> </span>
                </div>
            ) : (
                <div className="col-12 mt-2" id = "dashboard_image">
                    <span className="content"> Upload Image <span className="required">*</span> <ToolTip content = "Upload image"/> </span>
                    <ToolTip content = {!formData?.listing ? "Select listing first" : "Image" } >
                        <div className="dashboard_recent_gallery_upload_btn_box mt-2">
                            <label className="dashboard_recent_gallery_upload_btn">
                            <img src={fileUploadIcon} />
                            <h3 className="sub_text">Upload your jpg or png file</h3>
                            <input
                                type="file"
                                multiple={false}
                                data-max_length="20"
                                className="dashboard_recent_gallery_upload_input"
                                id="upload_image_file"
                                accept='image/png, image/jpeg, image/jpg'
                                onChange={ (e) => {
                                    handleImageChange(e, formData?.listing);
                                }}
                                disabled={formData?.listing ? false : true}
                            />
                            </label>
                        </div>
                    </ToolTip>
                    {errors?.image && <span className="content error_text"> {errors?.image} </span>}
                </div>
            ) }  
            <div className='d-flex flex-row justify-content-center align-items-center col-12 gap-3 mt-2'>
                <button className="btn btn-dark sp_button mr-2" type="button" onClick={() => {
                    setFormData({});
                } }> Clear </button>
                <button className="btn btn-primary sp_button" type="submit"> Submit </button>
            </div>
        </form>
    )
}

export default RecentEventsComponent