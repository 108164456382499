import ModalWrapper from './ModalWrapper/ModalWrapper';
import SearchableSelect from '../Inputs/Select';
import TextInput from '../Inputs/TextInput';
import { useState } from 'react';
const EVENT_TYPE = [
    {label : "Film Shootings", value : "film"},
    {label : "Tv Shootings", value : "tv"},
    {label : "Events", value : "event"},
    {label : "Staycations", value : "stay"},
]
const CITY = [
    {label : "Hyderabad", value : "hyderabad"},
    {label : "Warangal", value : "warangal"},
    {label : "Nizamabad", value : "nizamabad"},
    {label : "Karimnagar", value : "karimnagar"},
    {label : "Khammam", value : "khammam"},
    {label : "Mahbubnagar", value : "mahbubnagar"},
    {label : "Nalgonda", value : "nalgonda"},
    {label : "Ramagundam", value : "ramagundam"},
    {label : "Siddipet", value : "siddipet"},
    {label : "Suryapet", value : "suryapet"},
  ];
const ATTENDIES = [
    { value: "1-5", label: "1 - 5 people" },
    { value: "6-15", label: "6 - 15 people" },
    { value: "16-30", label: "16 - 30 people" },
    { value: "31-45", label: "31 - 45 people" },
    { value: "46-60", label: "46 - 60 people" },
    { value: "60-100000", label: "60+ people" },
];

const VENUE_TYPES = [
    { value: "venue", label: "Venue" },
    { value: "perPlate", label: "Per Plate" },
    { value: "perHour", label: "Per Hour" },
];

const VENUE_FOOD_TYPES = [
    { value: "nonVeg", label: "Veg/Non-Veg" },
    { value: "pureVeg", label: "Pure Veg" },
];

const POPULAR_TYPES = [
  { value: 'Farm House', label: 'Farm House' },
  { value: 'Banquet Hall', label: 'Banquet Hall' },
  { value: 'Villa', label: 'Villa' },
  { value: 'Convention Centre', label: 'Convention Centre' },
  { value: 'Resort', label: 'Resort' },
  { value: 'Conference Hall', label: 'Conference Hall' },
  { value: 'Cottage', label: 'Cottage' },
  { value: 'Farm Land', label: 'Farm Land' },
  { value: 'Guest House', label: 'Guest House' },
  { value: 'Open Air Lawn', label: 'Open Air Lawn' },
  { value: 'Home Stay', label: 'Home Stay' },
  { value: 'Party Hall', label: 'Party Hall' },
  { value: 'Tree House', label: 'Tree House' },
  { value: 'Palace', label: 'Palace' },
  { value: 'Hotels', label: 'Hotels' },
  { value: 'Manduva House', label: 'Manduva House' },
  { value: 'Sports Arena', label: 'Sports Arena' },
  { value: 'Mansion', label: 'Mansion' }
];
const OUTDOOR_AMENITIES = [
  { value: 'Outdoor Swimming Pool', label: 'Swimming Pool' },
  { value: 'Lawn', label: 'Lawn' },
  { value: 'Bonfire', label: 'Bonfire' },
  { value: `Children's Play Area`, label: `Children's Play Area` },
  { value: 'Barbeque', label: 'Barbeque' },
  { value: `Sun Deck`, label: `Sun Deck` },
  { value: 'Outdoor Sports', label: 'Outdoor Sports' },
  { value: 'Balcony / Terrace', label: 'Balcony / Terrace' },
];
const INDOOR_AMENITIES = [
  { value: 'Air Conditioning', label: 'Air Conditioning' },
  { value: 'Wi-Fi', label: 'Wi-Fi' },
  { value: 'Fire Place', label: 'Fire Place' },
  { value: 'Steam & Sauna', label: 'Steam & Sauna' },
  { value: 'Gym/ Fitness Centre', label: 'Gym/ Fitness Centre' },
  { value: 'Indoor Games', label: 'Indoor Games' },
];
const POLICIES = [
    { value: 'Music', label: 'Music' },
    { value: 'Alcohol', label: 'Alcohol' },
    { value: 'Smoking', label: 'Smoking' },
    { value: 'Outside Food', label: 'Outside Food' },
    { value: 'Caretaker', label: 'Caretaker' },
    { value: 'Non-vegetarian food', label: 'Non-vegetarian food' }
];
const SearchFilter = (props) => {
    const [filterData,setFilterData] = useState(props?.formData || {
        event: '',
        city: '',
        activity: '',
        location: '',
        area: '',
        amenities: [],
        policies: [],
        attendies: '',
        eventType : '',
        plateType : '',
    })
    const onChange = (e) => {
        const {name,value} = e.target
        const data = {
            ...filterData,
            [name] : value,
        }
        setFilterData(data)
    }
    const onChangeOptionEvent = (name,value) => {
        const data = {
            ...filterData,
            [name] : value,
            city : '',
            activity : '',
            location : '',
        }
        setFilterData(data)
        props.onChangeOptionEvent(name,value)
    }
    const onChangeOption = (name,value) => {
        const data = {
            ...filterData,
            [name] : value,
        }
        setFilterData(data)
    }
    const onChangeAttendies = (data) => {
        if(filterData?.attendies === data){
            setFilterData({
                ...filterData,
                attendies : '',
            })
        }else{
            setFilterData({
                ...filterData,
                attendies : data,
            })
        }
    }
    const onChangePopularType = (data) => {
        if(filterData?.location === data){
            setFilterData({
                ...filterData,
                location : '',
            })
        }else{
            setFilterData({
                ...filterData,
                location : data,
            })
        }
    }
    const onChangeVenueType = (type) => {
        setFilterData({
            ...filterData,
            eventType : type,
            plateType :''
        })
    }
    const onChangeFoodType = (type) => {
        setFilterData({
            ...filterData,
            plateType : type,
        })
    }
    const handleAmenities = (isTrue, value) => {
        if (isTrue) {
          filterData.amenities.push(value)
          setFilterData({ ...filterData })
        } else {
          const newArr = filterData?.amenities?.filter(function (item) {
            return item !== value
          })
          setFilterData({ ...filterData, amenities: newArr })
        }
    }
    const handlePolicies = (isTrue, value) => {
        if (isTrue) {
          filterData.policies.push(value)
          setFilterData({ ...filterData })
        } else {
          const newArr = filterData?.policies?.filter(function (item) {
            return item !== value
          })
          setFilterData({ ...filterData, policies: newArr })
        }
    }
    const onClear = () => {
        const data = {
            ...props?.formData,
            activity: '',
            location: '',
            area: '',
            amenities: [],
            policies: [],
            attendies: '',
            eventType : '',
            plateType : '',
        }
        props.onSubmit(data)
    }
    const onSubmit = () => {
        props.onSubmit(filterData)
    }
    return(
        <ModalWrapper heading="Filters" props={props}>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='d-lg-none w-100'>
                    <SearchableSelect
                        label='Seeking Locations for'
                        name='event'
                        options={EVENT_TYPE}
                        value={filterData?.event || ""}
                        onChange={onChangeOptionEvent}
                        placeholder='Select Event'
                        style={{zIndex : '4'}}
                    />
                </div>
                <div className='d-lg-none w-100' style={{zIndex : '3'}}>
                    <SearchableSelect
                        label='City'
                        name='city'
                        options={props?.cities}
                        value={filterData?.city || ""}
                        onChange={onChangeOption}
                        placeholder='Select city'
                        style={{zIndex : '3'}}
                        disabled={!filterData?.event}
                    />
                </div>
                <div className='w-100' style={{zIndex : '2'}}>
                    <SearchableSelect
                        label='Property type'
                        name='location'
                        options={props?.propTypes}
                        value={filterData?.location || ""}
                        onChange={onChangeOption}
                        placeholder='Select property type'
                        style={{zIndex : '2'}}
                        disabled={!filterData?.event}
                    />
                </div>
                <div className='w-100' style={{zIndex : '1'}}>
                    <SearchableSelect
                        label='Activity'
                        name='activity'
                        options={props?.activities}
                        value={filterData?.activity || ""}
                        onChange={onChangeOption}
                        placeholder='Select activity'
                        disabled={!filterData?.event}
                    />
                </div>
                <div className='w-100'>
                    <TextInput
                        id='filter_area'
                        label='Area'
                        name='area'
                        value={filterData?.area || ""}
                        onChange={onChange}
                        placeholder='Enter area'
                        title="Please enter a area"
                    />
                </div>
                <div className='w-100'>
                    <span className='sub_text heading_color_1'>Attendees</span>
                    <div className='mt-3'>
                        <div className='row'>
                            {ATTENDIES?.map((each)=> {
                                return(
                                    <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center mb-3'>
                                        <input
                                            id={each.value}
                                            type='checkbox'
                                            name='attendies'
                                            className='sp_check'
                                            value={each.value}
                                            checked={filterData?.attendies === each.value}
                                            onClick={() => onChangeAttendies(each.value)}
                                        />
                                        <label htmlFor={each.value} className='p-0 m-0 ml-2 content'>{each.label}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                { filterData?.event === "event" && (
                    <div className='w-100'>
                        <span className='sub_text heading_color_1'>Venue Type</span>
                        <div className='mt-3'>
                            <div className='row'>
                                {VENUE_TYPES?.map((each)=> {
                                    return(
                                        <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center mb-3'>
                                            <input
                                                id={each.value}
                                                type='checkbox'
                                                name='eventType'
                                                className='sp_check'
                                                value={each.value}
                                                checked={filterData?.eventType === each.value}
                                                onChange={() => onChangeVenueType(each.value)}
                                            />
                                            <label htmlFor={each.value} className='p-0 m-0 ml-2 content'>{each.label}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}
                { (filterData?.event === "event" && filterData?.eventType === "perPlate") &&  (
                    <div className='w-100'>
                        <span className='sub_text heading_color_1'>Food Type</span>
                        <div className='mt-3'>
                            <div className='row'>
                                {VENUE_FOOD_TYPES?.map((each)=> {
                                    return(
                                        <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center mb-3'>
                                            <input
                                                id={each.value}
                                                type='checkbox'
                                                name='plateType'
                                                className='sp_check'
                                                value={each.value}
                                                checked={filterData?.plateType === each.value}
                                                onChange={() => onChangeFoodType(each.value)}
                                            />
                                            <label htmlFor={each.value} className='p-0 m-0 ml-2 content'>{each.label}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}
                <div className='w-100'>
                    <span className='sub_text heading_color_1'>Popular Types</span>
                    <div className='mt-3'>
                        <div className='row'>
                            {POPULAR_TYPES?.map((each)=> {
                                return(
                                    <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center mb-3'>
                                        <input
                                            id={each.value}
                                            type='checkbox'
                                            name='location'
                                            className='sp_check'
                                            value={each.value}
                                            checked={filterData?.location === each.value}
                                            onChange={()=> onChangePopularType(each.value)}
                                        />
                                        <label htmlFor={each.value} className='p-0 m-0 ml-2 content'>{each.label}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-100'>
                    <span className='sub_text heading_color_1'>Outdoor Amenities</span>
                    <div className='mt-3'>
                        <div className='row'>
                            {OUTDOOR_AMENITIES?.map((each)=> {
                                return(
                                    <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center mb-3'>
                                        <input
                                            id={each.value}
                                            type='checkbox'
                                            name='amenities'
                                            className='sp_check'
                                            value={each.value}
                                            checked={filterData?.amenities?.includes(each.value)}
                                            onChange={(e) => handleAmenities(e.target.checked, each.value)}
                                        />
                                        <label htmlFor={each.value} className='p-0 m-0 ml-2 content'>{each.label}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-100'>
                    <span className='sub_text heading_color_1'>Indoor Amenities</span>
                    <div className='mt-3'>
                        <div className='row'>
                            {INDOOR_AMENITIES?.map((each)=> {
                                return(
                                    <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center mb-3'>
                                        <input
                                            id={each.value}
                                            type='checkbox'
                                            name='amenities'
                                            className='sp_check'
                                            value={each.value}
                                            checked={filterData?.amenities?.includes(each.value)}
                                            onChange={(e) => handleAmenities(e.target.checked, each.value)}
                                        />
                                        <label htmlFor={each.value} className='p-0 m-0 ml-2 content'>{each.label}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='w-100'>
                    <span className='sub_text heading_color_1'>Location Allows</span>
                    <div className='mt-3'>
                        <div className='row'>
                            {POLICIES?.map((each)=> {
                                return(
                                    <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center mb-3'>
                                        <input
                                            id={each.value}
                                            type='checkbox'
                                            name='policies'
                                            className='sp_check'
                                            value={each.value}
                                            checked={filterData?.policies?.includes(each.value)}
                                            onChange={(e) => handlePolicies(e.target.checked, each.value)}
                                        />
                                        <label htmlFor={each.value} className='p-0 m-0 ml-2 content'>{each.label}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className='search_filter_buttons_container'>
                    <button type='button' className='btn btn-secondary sp_button' onClick={onClear}>Clear</button>
                    <button type='button'  className='btn btn-primary sp_button ml-2' onClick={onSubmit}>Apply</button>
                </div>
            </div>
        </ModalWrapper>
    )
}
export default SearchFilter;