import ModalWrapper from './ModalWrapper/ModalWrapper';
import moment from 'moment';
const Enquiry = (props) => {
    return(
        <ModalWrapper heading={``} props={props} size={'lg'}>
            {/* <div className='d-none d-lg-block  content m-2'>
                <div >
                    <table className="dash_home_enquiry_table mb-2">
                        <tbody>
                            <tr className='heading_color_1'>
                                <td>Date</td>
                                <td>Id</td>
                                <td>Type</td>
                                <td>Property Id</td>
                            </tr>
                            <tr>
                                <td>{moment(props?.data?.createdAt).format('DD-MMM-YYYY hh:mm a')}</td>
                                <td>{props?.data?.enquiryId}</td>
                                <td>{props?.data?.type}</td>
                                <td>{props?.data?.data?.locationId}</td>
                            </tr>
                            </tbody>
                    </table>
                    {props?.data?.type === 'Get In Touch' && (
                        <table className="dash_home_enquiry_table mb-2">
                            <tbody>
                            <tr className='heading_color_1'>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>Mobile number</td>
                                    <td>Message</td>
                                </tr>
                                <tr>
                                    <td>{props?.data?.data?.name}</td>
                                    <td>{props?.data?.data?.email}</td>
                                    <td>{props?.data?.data?.mobileNumber}</td>
                                    <td className='sp_pre_wrap'>{props?.data?.data?.message}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {props?.data?.type === 'Contact Host' && (
                        <table className="dash_home_enquiry_table mb-2">
                            <tbody>
                            <tr className='heading_color_1'>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>Mobile number</td>
                                </tr>
                                <tr>
                                    <td>{props?.data?.data?.name}</td>
                                    <td>{props?.data?.data?.email}</td>
                                    <td>{props?.data?.data?.mobileNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {props?.data?.type === 'Schedule Property Visit' && (
                        <table className="dash_home_enquiry_table mb-2">
                            <tbody>
                            <tr className='heading_color_1'>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>Mobile number</td>
                                    <td>Date</td>
                                    <td>Message</td>
                                </tr>
                                <tr>
                                    <td>{props?.data?.data?.name}</td>
                                    <td>{props?.data?.data?.email}</td>
                                    <td>{props?.data?.data?.mobileNumber}</td>
                                    <td>{props?.data?.data?.date}</td>
                                    <td className='sp_pre_wrap'>{props?.data?.data?.additionalInfo}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {props?.data?.type === 'Booking Request' && (
                        <table className="dash_home_enquiry_table mb-2">
                            <tbody>
                            <tr className='heading_color_1'>
                                    <td>Event Type</td>
                                    <td>Name</td>
                                    <td>Email</td>
                                    <td>Mobile number</td>
                                    {props?.data?.data?.event === 'stay' ? (
                                        <>
                                            <td>Check in</td>
                                            <td>Check out</td>
                                        </>

                                    ) : (
                                        <>
                                            <td>Date</td>
                                            <td>Time</td>
                                        </>
                                    )}
                                </tr>
                                <tr>
                                    <td>{props?.data?.data?.event}</td>
                                    <td>{props?.data?.data?.guestName}</td>
                                    <td>{props?.data?.data?.guestEmail}</td>
                                    <td>{props?.data?.data?.guestNumber}</td>
                                    {props?.data?.data?.event === 'stay' ? (
                                        <>
                                            <td>{moment(props?.data?.data?.checkIn).format('DD-MMM-YYYY')}</td>
                                            <td>{moment(props?.data?.data?.checkOut).format('DD-MMM-YYYY')}</td>
                                        </>

                                    ) : (
                                        <>
                                            <td>{moment(props?.data?.data?.startDate).format('DD-MMM-YYYY')}</td>
                                            <td>{props?.data?.data?.startTime}</td>
                                        </>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </div> */}
            <div className='content text_color_1'>
                <span className='sub_text heading_color_1'> Enquiry Received Details : </span>
                <table className="dash_home_enquiry_table mb-2">
                    <tbody>
                        <tr>
                            <td className='heading_color_1 dash_home_enquiry_left_cell'>Date</td>
                            <td >{moment(props?.data?.createdAt).format('DD-MMM-YYYY hh:mm a')}</td>
                        </tr>
                        <tr>
                            <td className='heading_color_1 dash_home_enquiry_left_cell'>Enquiry Id</td>
                            <td >{props?.data?.enquiryId}</td>
                        </tr>
                        <tr>
                            <td className='heading_color_1 dash_home_enquiry_left_cell'>Type</td>
                            <td >{props?.data?.type}</td>
                        </tr>
                        <tr>
                            <td className='heading_color_1 dash_home_enquiry_left_cell'>Property Id</td>
                            <td >{props?.data?.data?.locationId}</td>
                        </tr>
                        {/* <tr>
                            <td >Enquiry Details</td>
                            <td >
                                description
                            </td>
                        </tr> */}
                        </tbody>
                </table>
                <span className='sub_text heading_color_1 mt-3 mb-1'> {props?.data?.type === "Booking Request" ? "Booking" : "Enquiry"} Requested Details : </span>
                {props?.data?.type === 'Get In Touch' && (
                    <table className="dash_home_enquiry_table mb-2">
                        <tbody>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Name</td>
                                <td >{props?.data?.data?.name}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Email</td>
                                <td >{props?.data?.data?.email}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Mobile number</td>
                                <td >{props?.data?.data?.mobileNumber}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Message</td>
                                <td className='sp_pre_wrap'>{props?.data?.data?.message || "N/A"}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                {props?.data?.type === 'Contact Host' && (
                    <table className="dash_home_enquiry_table mb-2">
                        <tbody>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Name</td>
                                <td >{props?.data?.data?.name}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Email</td>
                                <td >{props?.data?.data?.email || "N/A"}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Mobile number</td>
                                <td >{props?.data?.data?.mobileNumber || "N/A"}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                {props?.data?.type === 'Schedule Property Visit' && (
                    <table className="dash_home_enquiry_table mb-2">
                        <tbody>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Name</td>
                                <td >{props?.data?.data?.name}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Email</td>
                                <td >{props?.data?.data?.email || "N/A"}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Mobile number</td>
                                <td >{props?.data?.data?.mobileNumber || "N/A"}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Date</td>
                                <td >{props?.data?.data?.date}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Message</td>
                                <td className='sp_pre_wrap'>{props?.data?.data?.additionalInfo || "N/A"}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                {props?.data?.type === 'Booking Request' && (
                    <table className="dash_home_enquiry_table mb-2">
                        <tbody>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Event</td>
                                <td >{props?.data?.data?.event}</td>
                            </tr>
                            {props?.data?.data?.event !== 'stay' && (
                                <tr>
                                    <td className='heading_color_1 dash_home_enquiry_left_cell'>Sub Event</td>
                                    <td >{props?.data?.data?.subEvent}</td>
                                </tr>
                            )}
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Name</td>
                                <td >{props?.data?.data?.guestName}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Email</td>
                                <td >{props?.data?.data?.guestEmail}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Mobile number</td>
                                <td >{props?.data?.data?.guestNumber}</td>
                            </tr>
                            <tr>
                                <td className='heading_color_1 dash_home_enquiry_left_cell'>Guests</td>
                                <td >{props?.data?.data?.event === 'stay' ? `Adults : ${props?.data?.data?.adults} ${ props?.data?.data?.children && `, Children : ${props?.data?.data?.children}`}` : props?.data?.data?.guests }</td>
                            </tr>
                            {props?.data?.data?.event === 'stay' ? (
                                <>
                                    <tr>
                                        <td className='heading_color_1 dash_home_enquiry_left_cell'>Check in</td>
                                        <td >{moment(props?.data?.data?.checkIn).format('DD-MMM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td className='heading_color_1 dash_home_enquiry_left_cell'>Check out</td>
                                        <td >{moment(props?.data?.data?.checkOut).format('DD-MMM-YYYY')}</td>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    <tr>
                                        <td className='heading_color_1 dash_home_enquiry_left_cell'>Date</td>
                                        <td >{moment(props?.data?.data?.startDate).format('DD-MMM-YYYY hh:mm a')}</td>
                                    </tr>
                                    <tr>
                                        <td className='heading_color_1 dash_home_enquiry_left_cell'>Time</td>
                                        <td >{props?.data?.data?.startTime}</td>
                                    </tr>
                                    <tr>
                                        <td className='heading_color_1 dash_home_enquiry_left_cell'>Hours Requested</td>
                                        <td >{props?.data?.data?.noOfHours} Hrs</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </ModalWrapper>
    )
}
export default Enquiry;