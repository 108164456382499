import { useState,useRef } from 'react';
import { useAuth } from '../Utils/Auth';
import ModalWrapper from './ModalWrapper/ModalWrapper';
import MobileInput from '../Inputs/MobileInput';
import NumberInput from '../Inputs/NumberInput';
import bcrypt from "bcryptjs-react" 
import { sendRegisterOtp,updateUser } from '../Apis/Api';
const  VerifyMobile = (props) => {
    console.log(props)
    const {notify,triggerLoader} = useAuth()
    const [tab,setTab] = useState('verifyMobile')
    const [formData,setFormData] = useState({mobile : props?.mobile || ''})
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(60);
    const intervalRef = useRef(null);
    const runTIme = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setTime((prevState) => {
                if (prevState <= 1) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    return 0;
                }
                return prevState - 1;
            });
        }, 1000);
    }
    const changeTab = (tab) => {
        setTab(tab)
    }
    const onBackTab = () => {
        setTab('verifyMobile')
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }else{
            setErrors({...errors,[name] : `This field is required`})
        }
    };
    const validateIndianMobile = (mobile) => {
        const regex = /^\+91[6-9]\d{9}$/;
        return regex.test(mobile) ? '' : 'Invalid mobile format';
    };
    async function validateOtpFunc(userOtp) {
        const isOk = await bcrypt.compare(userOtp, otp);
        return isOk ? '' : 'Invalid Code'
    }
    const validateForm = async () => {
        const newErrors = {
            mobile: formData.mobile ? validateIndianMobile(formData.mobile) : 'Mobile is required',
            otp: tab === 'validateOtp' ? formData?.otp ? await validateOtpFunc(formData?.otp) : 'Code is required' : '',
        };
        setErrors(newErrors);
    
        // Return false if there are errors
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById('modal_'+ key);
                if (inputElement) {
                    inputElement.scrollIntoView({ behavior: 'smooth' });
                }
                return false
            }
        }
        return true
    };
    const onSubmit = async (e) => {
        e.preventDefault()
        if (! await validateForm()) {
            return null
        }
        triggerLoader(true)
        try{
            let resp
            switch (tab) {
                case 'verifyMobile':
                    resp = await sendRegisterOtp(formData)
                    setOtp(resp?.data?.otp)
                    changeTab('validateOtp')
                    setTime(60)
                    runTIme()
                    break;
                case 'validateOtp':
                    resp = await updateUser({...formData,mobileVerified : true})
                    props.onSuccess()
                    break;
                default:
                    console.log('success')
                    break;
            }
            notify(resp?.data?.message,'success')
        }catch(error){
            console.log(error)
            switch (error?.response?.data?.type) {
                case 'server':
                    notify(error?.response?.data?.error,'error')
                    break;
                case 'email':
                    setErrors({email : error?.response?.data?.error})
                    break;
                default:
                    notify('Error while validating data','error')
                    break;
            }
        }
        triggerLoader(false) 
    }
    const resendCode = async () => {
        if(time !== 0){
            return null
        }
        triggerLoader(true)
        try{
            let resp = await sendRegisterOtp(formData)
            setFormData({
                ...formData,
                otp : ''
            })
            setErrors({})
            notify(resp?.data?.message,'success')
            setOtp(resp?.data?.otp)
            setTime(60)
            runTIme()
        }catch(error){
            notify(error?.response?.data?.error || 'Error while sending otp','error')
        }
        triggerLoader(false)
    }

    const verifyMobile = () => {
        return(
            <form onSubmit={onSubmit}>
                <div className="mb-2">
                    <MobileInput
                        id='modal_mobile'
                        label='Mobile'
                        name='mobile'
                        value={formData?.mobile || ""}
                        onChange={onChange}
                        placeholder='+910000000000'
                        validate={(value) => (value ? '' : 'Mobile number is required')}
                        errorMessage={errors?.mobile}
                        required={true}
                        title="Please enter a valid 10-digit Indian mobile number starting with +91"
                    />
                </div>
                <button type='submit' className="btn btn-primary sp_button w-100">Get Code</button>
            </form>
        )
    }
    const validateOtp = () => {
        return(
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <span className="main_text heading_color_1 mb-4 text-center">Two-Factor Verification</span>
                <span className="content text_color_3 text-center mb-4">Enter the verification code we sent to</span>
                <span className="content heading_color_1 text-center mb-4">{formData?.mobile}{' '}<span className="content heading_color_3 cursor" onClick={onBackTab}>(Edit)</span></span>
                <form className="d-flex flex-column w-100 mb-4" onSubmit={onSubmit}>
                    <div className="mb-2">
                        <NumberInput
                            id='modal_otp'
                            label='Type your 4 digit security code'
                            name='otp'
                            value={formData?.otp || ""}
                            onChange={onChange}
                            placeholder='Enter 4 digit code'
                            validate={(value) => (value ? '' : 'Otp is required')}
                            errorMessage={errors?.otp}
                            required={true}
                            title="Please enter 4 digit security code"
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button w-100 mb-4">Verify</button>
                    <span className="text_color_3 text-center fw-semibold fs-6 mb-2 content">Didn’t get the code ? You can  <span className={`${time === 0 ? "heading_color_3" : "text_color_3"} fw-semibold content cursor`} onClick={resendCode}>Resend</span> the code{time !== 0 && (<span className="sub_text heading_color_3"> in {time}</span>)}</span>
                </form>
            </div>
        )
    }
    return(
        <ModalWrapper heading="Verify Mobile" props={props}>
            {tab==='verifyMobile' && verifyMobile()}
            {tab==='validateOtp' && validateOtp()}
        </ModalWrapper>
    )
}
export default VerifyMobile;