import { useEffect, useState } from "react";
import ModalWrapper from "./ModalWrapper/ModalWrapper";
import DateInput from "../Inputs/DateInput";

const DatesFilter = (props) => {

    const [data, setData] = useState({
        startDate : "",
        endDate : "",
    });

    const handleClear = () => {
        setData({
            startDate : "",
            endDate : "",
        });
        props?.onSuccess({
            startDate : "",
            endDate : "",
        });
    }

    const handleApply = () => {
        props?.onSuccess(data);
        props?.onHide();
    }

    const onChangeDate = (e) => {
        const {name, value} = e?.target;
        setData({
            ...data,
            [name] : value,
            ...(name === "startDate" && {endDate : ""}),
        })
    };

    useEffect(() => {
        setData({...props?.data});
    },[props?.data] );


    return(
        <ModalWrapper heading={props?.title} props={props}>
            <div className="d-flex flex-column align-items-start">
                <div className="dash_home_input_wrapper w-100">
                    <span className="content text_color_1 mt-1 " style={{width : "58px"}}> From : </span>
                    <div className="dash_home_header_right_wrapper_input w-100" style={{marginBottom : "-15px"}}>
                        <DateInput
                            id='start_date'
                            name='startDate'
                            value={data?.startDate || ""}
                            onChange={onChangeDate}
                            placeholder='dd-mm-yyyy'
                            // min={new Date().toISOString().split('T')[0]}
                            max={new Date().toISOString().split('T')[0]}
                        />
                    </div>
                </div>
                <div className="dash_home_input_wrapper w-100">
                    <span className="content text_color_1 mt-1 " style={{width : "58px"}}> To : </span>
                    <div className="dash_home_header_right_wrapper_input w-100" style={{marginBottom : "-15px"}}>
                        <DateInput
                            id='end_date'
                            name='endDate'
                            value={data?.endDate || ""}
                            onChange={onChangeDate}
                            placeholder='dd-mm-yyyy'
                            min={ data?.startDate 
                                ? new Date(data?.startDate)
                                // ? new Date(new Date(data?.startDate).setDate(new Date(data?.startDate).getDate() + 1))
                                    .toISOString()
                                    .split('T')[0]
                                : ""
                                }
                            max={new Date().toISOString().split('T')[0]}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-end mt-2 align-self-end" >
                    <button type='button' onClick={handleClear} id="privacy_terms_submit_buttton" className="btn btn-secondary sp_button agree-btn mr-3"> Clear </button>
                    <button type='button' onClick={handleApply} id="privacy_terms_submit_buttton" className="btn btn-primary sp_button agree-btn"> Apply </button>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default DatesFilter