import "./Fallback.css"
const Fallback = () => {
    return(
        <div>
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-rect"></div>
            <div className="skeleton skeleton-text"></div>
            <div className="skeleton skeleton-rect"></div>
        </div>
    )
}
export default Fallback;