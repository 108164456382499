import { useEffect } from "react";
import ModalWrapper from "./ModalWrapper/ModalWrapper"
import RecentEventsComponent from "../Components/RecentEvents/RecentEvents";

const RecentEventsModal = (props) => {
    const {title} = props;

    return(
        <ModalWrapper heading={title} props={props} size="lg">
            <RecentEventsComponent props = {props}/>
        </ModalWrapper>
    )
}

export default RecentEventsModal