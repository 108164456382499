import React, { Suspense, lazy, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { useUser } from './Utils/Auth';
import { PropertyProvider } from './Pages/PropertyPage/Components/PropertyProvider';
import Fallback from './Utils/Fallbacks/Fallback';
import ProtectedRoute from './Utils/ProtectedRoute';
import Auth from './Pages/Auth/Auth';
import EditProfile from './Pages/EditProfile/EditProfile';
import Enquiries from './Pages/Enquiries/Enquiries';
import RecentEvents from './Pages/Dashboard/RecentEvents/RecentEvents';
import Search from './Pages/Search/Search';
import Activity from './Pages/Activity/Activity';
import Favourites from './Pages/Favourites/Favourites';
import Compare from './Pages/Compare/Compare';
import Notifications from './Pages/Notifications/Notifications';

function App() {
// lazy pages importing start
const Home = lazy(() => import('./Pages/Home/Home'));
const AboutUs = lazy(()=>import('./Pages/AboutUs/AboutUs'));
const Host = lazy(()=>import('./Pages/Host/Host'));
const TermsAndConiditions = lazy(()=>import('./Pages/TermsAndConiditions/TermsAndConiditions'));
const PrivacyPolicy = lazy(()=>import('./Pages/PrivacyPolicy/PrivacyPolicy'));
const Career = lazy(()=>import('./Pages/Career/Career'));
const ContactUs = lazy(()=>import('./Pages/ContactUs/ContactUs'));
const Cancellation = lazy(()=>import('./Pages/Cancellation/Cancellation'));
const NotFound = lazy(()=>import('./Pages/NotFound/NotFound'));
const TypeOfProperty = lazy(() => import("./Pages/ListYourspace/TypeOfProperty"));
const BasicInformation = lazy(() => import("./Pages/ListYourspace/BasicInformation"));
const Location = lazy(() => import("./Pages/ListYourspace/Location"));
const AllAmenities = lazy(() => import("./Pages/ListYourspace/AllAmenities"));
const RoomsOrSpaces = lazy(() => import("./Pages/ListYourspace/RoomsOrSpaces"));
const PricingAndTimings = lazy(() => import("./Pages/ListYourspace/PricingAndTimings"));
const Gallery = lazy(() => import("./Pages/ListYourspace/Gallery"));
const Policies = lazy(() => import("./Pages/ListYourspace/Policies"));
const Activities = lazy(() => import("./Pages/ListYourspace/Activities"));
const PropertyManagerDetails = lazy(() => import("./Pages/ListYourspace/PropertyManagerDetails"));
const Subscription = lazy(() => import("./Pages/ListYourspace/Subscription"));
const LandingPage = lazy(()=> import("./Pages/LandingPage/LandingPage"));
const PropertyPage = lazy(() => import("./Pages/PropertyPage/PropertyPage"))
const Dashboard = lazy(() => import("./Pages/Dashboard/Home/Home"));
const Listings = lazy(() => import("./Pages/Dashboard/Listings/Listings"));
const Calendar = lazy(() => import("./Pages/Dashboard/Calendar/Calendar"));
const Analytics = lazy(() => import("./Pages/Dashboard/Analytics/Analytics"));
const Support = lazy(() => import("./Pages/Dashboard/Support/Support"));
const Settings = lazy(() => import("./Pages/Dashboard/Settings/Settings"));
const Newsletter = lazy(() => import("./Pages/NewsLetter/Newsletter") ) 

// lazy pages importing end

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<Fallback/>}>
        <Home />
      </Suspense>
    ),
    errorElement: <NotFound />,
  },
  {
    path: "/edit-profile",
    element: (
        <ProtectedRoute>
          <EditProfile />
        </ProtectedRoute>
    ),
  },
  {
    path: "/notifications",
    element: (
        <ProtectedRoute>
          <Notifications />
        </ProtectedRoute>
    ),
  },
  {
    path: "/list-your-space/type-of-property",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <TypeOfProperty />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/basic-information",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <BasicInformation />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/location",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Location />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/amenities",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <AllAmenities />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/rooms-spaces",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <RoomsOrSpaces />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/pricing-and-timings",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <PricingAndTimings />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/gallery",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Gallery />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/policies",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Policies />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/activities",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Activities />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/property-manager-details",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <PropertyManagerDetails />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/list-your-space/subscription",
    element: (
      <Suspense fallback={<Fallback/>}>
        {/* <ProtectedRoute> */}
          <Subscription />
        {/* </ProtectedRoute> */}
      </Suspense>
    ),
  },
  {
    path: "/about-us",
    element: (
      <Suspense fallback={<Fallback/>}>
        <AboutUs />
      </Suspense>
    ),
  },
  {
    path: "/host",
    element: (
      <Suspense fallback={<Fallback/>}>
        <Host />
      </Suspense>
    ),
  },
  {
    path: "/landing-page",
    element: (
      <Suspense fallback={<Fallback/>}>
        <LandingPage />
      </Suspense>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <Suspense fallback={<Fallback/>}>
        <TermsAndConiditions />
      </Suspense>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <Suspense fallback={<Fallback/>}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/careers",
    element: (
      <Suspense fallback={<Fallback/>}>
        <Career />
      </Suspense>
    ),
  },
  {
    path: "/contact-us",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ContactUs />
      </Suspense>
    ),
  },
  {
    path: "/favourites",
    element: (
      <ProtectedRoute>
        <Favourites />
      </ProtectedRoute>
    ),
  },
  {
    path: "/compare",
    element: (
      <ProtectedRoute>
        <Compare />
      </ProtectedRoute>
    ),
  },
  {
    path: "/cancellation-and-refund",
    element: (
      <Suspense fallback={<Fallback/>}>
        <Cancellation />
      </Suspense>
    ),
  },
  {
    path: "/search/*",
    element: (
        <Search />
    ),
  },
  {
    path: "/locations/*",
    element: (
        <Activity />
    ),
  },
  {
    path: "/enquiries",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Enquiries />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/recent-events",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <RecentEvents /> 
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/property/:id",
    element: (
      <Suspense fallback={<Fallback/>}>
        <PropertyProvider>
          <PropertyPage />
        </PropertyProvider>
      </Suspense>
    ),
  },
  {
    path: "/home",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/listings",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Listings />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/calendar",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Calendar />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/analytics",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Analytics />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/support",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Support />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/settings",
    element: (
      <Suspense fallback={<Fallback/>}>
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      </Suspense>
    ),
  },
  {
    path: "/newsletter",
    element: (
      <Suspense fallback={<Fallback/>}>
          <Newsletter />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<Fallback/>}>
        <NotFound />
      </Suspense>
    ),
  },
]);
  const {user,getUser} = useUser()
  useEffect(()=>{
    const token = localStorage.getItem("auth");
    if(!user && token){
      getUser(token)
    }
  },[getUser, user]);

  return <RouterProvider router={router} />;
}

export default App;