import "./Autheniticator.css"
import { useState,useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import bcrypt from "bcryptjs-react"
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextInput from "../../Inputs/TextInput";
import EmailInput from "../../Inputs/EmailInput";
import MobileInput from "../../Inputs/MobileInput";
import NumberInput from "../../Inputs/NumberInput";
import PasswordInput from "../../Inputs/PasswordInput";
import logo from "../../Assets/Images/Auth/spotlet_logo.webp"
import { sendRegisterOtp,registerUser,registerGoogleUser,loginUser,sendForgotOtp,resetUserPassword,resendOtp, sendLoginOtp, updateUserStatus } from "../../Apis/Api";
import { useAuth } from "../../Utils/Auth";
import { useUser } from "../../Utils/Auth";
import { gapi } from "gapi-script";
const Autheniticator = ({activeTab,onSuccess}) => {
    const [googleClientId, setGoogleClientId] = useState(
        "837678588128-701v5ikaevb6ahi5bv0ps700q7lqit9d.apps.googleusercontent.com"
    );
    const {notify,triggerLoader, confirm, closeConfirmToast} = useAuth()
    const {getUser} = useUser()
    const [tab,setTab] = useState(activeTab || 'register')
    const [prevTab,setPrevTab] = useState('')
    const [formData,setFormData] = useState({})
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(60);
    const intervalRef = useRef(null);
    // for login with password and code 
    const [loginMethod, setLoginMethod] = useState("both");

    const runTIme = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setTime((prevState) => {
                if (prevState <= 1) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    return 0;
                }
                return prevState - 1;
            });
        }, 1000);
    }
    const changeTab = (nextTab) => {
        switch (nextTab) {
            case 'register':
                setFormData({})
                break;
            case 'login':
                setFormData({})
                break;
            case 'registerEmailForm':
                setFormData({
                    ...formData,
                    mobile : '',
                    confirm_password : '',
                    otp : '',
                })
                break;
            case 'registerMobileForm':
                setFormData({
                    ...formData,
                    email : '',
                    confirm_password : '',
                    otp : '',
                })
                break;
            case 'loginMobileForm':
                setFormData({
                    ...formData,
                    name : '',
                    email : '',
                    confirm_password : '',
                    otp : '',
                })
                break;
            case 'loginEmailForm':
                setFormData({
                    ...formData,
                    name : '',
                    mobile : '',
                    confirm_password : '',
                    otp : '',
                })
                break;
            case 'registerOtp':
                setFormData({
                    ...formData,
                    otp : ''
                })
                break;
            case 'forgotEmail':
                setFormData({
                    ...formData,
                    name :'',
                    mobile : '',
                    password :'',
                    confirm_password :'',
                    otp :'',
                })
                break;
            case 'forgotMobile':
                setFormData({
                    ...formData,
                    name :'',
                    email : '',
                    password :'',
                    confirm_password :'',
                    otp :'',
                })
                break;
            case 'resetPassword':
                setFormData({
                    ...formData,
                    name :'',
                    password :'',
                    confirm_password :'',
                    otp :'',
                })
                break;
        
            default:
                break;
        }
        setErrors({})
        setPrevTab(tab)
        setTab(nextTab)
    }
    const onBackTab = () => {
        switch (tab) {
            case 'registerEmailForm':
                setTab('register')
                break;
            case 'registerMobileForm':
                setTab('register')
                break;
            case 'loginMobileForm':
                setTab('login');
                setLoginMethod("both");
                break;
            case 'loginEmailForm':
                setTab('login');
                setLoginMethod("both");
                break;
            case 'registerOtp':
                setTab(prevTab)
                break;
            case 'forgotEmail':
                setTab('loginEmailForm')
                break;
            case 'forgotMobile':
                setTab('loginMobileForm')
                break;
            case 'resetPassword':
                setTab(prevTab)
                break;
        
            default:
                break;
        }
        setErrors({});
        if (intervalRef?.current) {
            clearInterval(intervalRef?.current);
        }
    }
    const onSuccessRegister = async(res) => {
        triggerLoader(true)
        let user = {
            fullName: res?.profileObj?.name,
            mobile: "",
            email: res?.profileObj?.email,
            password: "",
            profile_pic: res?.profileObj?.imageUrl || '',
            googleLogin: true,
            emailVerified: true,
            mobileVerified: false,
        };
        try{
            const resp = await registerGoogleUser(user)
            getUser(resp?.data?.token)
            onSuccess()
            notify(resp?.data?.message,'success')
        }catch(error){
            // console.log(error)
            notify(error?.response?.data?.error || 'Error while registering with google','error')
        }
        triggerLoader(false)
    }
    const onFailureRegister = (error) => {
        console.log("google failed:", error);
        // notify('Error while registering with google','error')
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }else{
            setErrors({...errors,[name] : `This field is required`})
        }
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return regex.test(email) ? '' : 'Invalid email format';
    };
    const validateIndianMobile = (mobile) => {
        const regex = /^\+91[6-9]\d{9}$/;
        return regex.test(mobile) ? '' : 'Invalid mobile number';
    };
    function validatePassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
        return regex.test(password) ? '' : 'Invalid password';
    }
    function validateConfirmPassword(password) {
        return formData.password === password ? '' : ' New password and confirm password are not the same';
    }
    async function validateOtp(userOtp) {
        const isOk = await bcrypt.compare(userOtp, otp);
        return isOk ? '' : 'Invalid Code'
    }
    const validateName = (name) => {
        const regex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
        const trimmedName = name.trim();  // Removes leading/trailing spaces
        if (!trimmedName) {
            return "Name is required";
        } else if (!regex.test(trimmedName)) {
            return 'Name can only contain letters and spaces';
        } else if (trimmedName.length < 2) {
            return 'Name should be minimum 2 letters';
        }
        return '';
    };
    const validateForm = async () => {
        let newErrors = {
            name: (tab === 'registerEmailForm' || tab === 'registerMobileForm') ? formData.name ? validateName(formData?.name) : 'Name is required' : '',
            email: (tab === 'registerEmailForm' || tab === 'loginEmailForm' || tab === 'forgotEmail') ?  formData.email ? validateEmail(formData.email) : 'Email address is required' : '',
            mobile: (tab === 'registerMobileForm' || tab === 'loginMobileForm' || tab === 'forgotMobile') ?  formData.mobile ? validateIndianMobile(formData.mobile) : 'Mobile number is required' : '',
            // password: ((tab !== 'forgotEmail' && tab !== 'forgotMobile') && ( (tab === 'loginEmailForm' || tab === 'loginMobileForm') && loginMethod === "password")  || (tab === 'registerEmailForm' || tab === 'registerMobileForm') )  ? formData.password ? validatePassword(formData.password) : 'Password is required' : '',
            password: ((tab !== 'forgotEmail' && tab !== 'forgotMobile') && ( loginMethod === 'password' || tab === 'registerEmailForm' || tab === 'registerMobileForm' ))   ? formData.password ? validatePassword(formData.password) : 'Password is required' : '',
            confirm_password: (tab === 'resetPassword') ? formData.confirm_password ? validateConfirmPassword(formData.confirm_password) : 'Confirm password is required' : '',
            otp:(tab === 'registerOtp' || tab === 'resetPassword' || loginMethod === "code") ? formData?.otp ? await validateOtp(formData?.otp) : 'Code is required' : '',
        };
        console.log(newErrors)
        setErrors(newErrors);
        // Return false if there are errors
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById('modal_'+ key);
                if (inputElement) {
                    inputElement.scrollIntoView({ behavior: 'smooth' });
                }
                return false
            }
        }
        return true
    };

    const activateUser = async() => {
        try{
            const response = await updateUserStatus(formData);
            notify(response?.data?.message, "success");
            getUser(response?.data?.token);
            onSuccess();
        } catch(error) {
            console.log(error?.response?.data?.error)
            notify(error?.response?.data?.error, "error")
        }
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        if (! await validateForm()) {
            return null
        }
        triggerLoader(true)
        try{
            console.log(tab)
            let resp
            switch (tab) {
                case 'registerEmailForm':
                    resp = await sendRegisterOtp(formData)
                    setOtp(resp?.data?.otp)
                    changeTab('registerOtp')
                    setTime(60)
                    runTIme()
                    break;
                case 'registerMobileForm':
                    resp = await sendRegisterOtp(formData)
                    setOtp(resp?.data?.otp)
                    changeTab('registerOtp')
                    setTime(60)
                    runTIme()
                    break;
                case 'registerOtp':
                    resp = await registerUser({...formData,})
                    getUser(resp?.data?.token)
                    onSuccess()
                    break;
                case 'loginEmailForm':
                    resp = await loginUser(formData)
                    getUser(resp?.data?.token)
                    onSuccess()
                    break;
                case 'loginMobileForm':
                    resp = await loginUser(formData)
                    getUser(resp?.data?.token)
                    onSuccess()
                    break;
                case 'forgotEmail':
                    resp = await sendForgotOtp(formData)
                    setOtp(resp?.data?.otp)
                    changeTab('resetPassword')
                    setTime(60)
                    runTIme()
                    break;
                case 'forgotMobile':
                    resp = await sendForgotOtp(formData)
                    setOtp(resp?.data?.otp)
                    changeTab('resetPassword')
                    setTime(60)
                    runTIme()
                    break;
                case 'resetPassword':
                    resp = await resetUserPassword(formData)
                    changeTab('login')
                    runTIme()
                    break;
                default:
                    console.log('success')
                    break;
            }
            notify(resp?.data?.message,'success')
        }catch(error){
            // console.log(error)
            switch (error?.response?.data?.type) {
                case 'server':
                    console.log("error", error?.response);
                    if (error?.response?.status === 412) {
                        closeConfirmToast();
                        confirm(error?.response?.data?.error, "Activate", () => activateUser());
                    }else {
                        notify(error?.response?.data?.error,'error')
                    }
                    break;
                case 'email':
                    setErrors({email : error?.response?.data?.error})
                    break;
                case 'mobile':
                    setErrors({mobile : error?.response?.data?.error})
                    break;
                case 'password':
                    setErrors({password : error?.response?.data?.error})
                    break;
                default:
                    notify('Error while validating data','error')
                    break;
            }
        }
        triggerLoader(false) 
    }
    const resendCode = async () => {
        if(time !== 0){
            return null
        }
        triggerLoader(true)
        try{
            let resp
            if(tab === 'registerEmailForm' || tab === 'registerMobileForm'){
                resp = await sendRegisterOtp(formData)
            }else{
                resp = await resendOtp(formData)
            }
            setFormData({
                ...formData,
                otp : ''
            })
            setErrors({})
            notify(resp?.data?.message,'success')
            setOtp(resp?.data?.otp)
            setTime(60)
            runTIme()
        }catch(error){
            notify(error?.response?.data?.error || 'Error while sending otp','error')
        }
        triggerLoader(false)
    }
    useEffect(() => {
        gapi.load("client:auth2",() =>{
            gapi.auth2.init({clientId:googleClientId})
        })
    },[])

    // functions for login with password and code 

    const onClickLoginWithCode = async() => {
        if (! await validateForm()) {
            return null
        }
        try {
            const resp = await sendLoginOtp(formData);
            setLoginMethod("code");
            setOtp(resp?.data?.otp)
            setTime(60);
            runTIme();
        } catch(error) {
            // console.log("error", error);
            switch (error?.response?.data?.type) {
                case 'server':
                    notify(error?.response?.data?.error,'error')
                    break;
                case 'email':
                    setErrors({email : error?.response?.data?.error})
                    break;
                case 'mobile':
                    setErrors({mobile : error?.response?.data?.error})
                    break;
                default:
                    notify('Error while validating data','error')
                    break;
            }
        }
    }

    //component start
    const google = (text) => {
        return(
            <GoogleLogin
                clientId={googleClientId}
                buttonText={text || 'Google'}
                onSuccess={onSuccessRegister}
                onFailure={onFailureRegister}
                cookiePolicy={"single_host_origin"}
                isSignedIn={false}
                className="auth_button auth_button_google content cursor"
                // disabled={region === 'India'}
            />
        )
    }
    const register = () => {
        return(
            <>
            <span className="main_text heading_color_1 mb-2">Register</span>
            {/* <span className="sub_text heading_color_1 text-center mb-2">Sign Up to Discover Film Shooting locations, Event Venues and Staycation Properties</span> */}
            <span className="content text_color_1 text-center mb-2">Sign Up to Discover Film Shooting locations, Event Venues and Staycation Properties</span>
            <div className="d-flex flex-column justify-content-between align-items-center w-100 mb-2">
                {google('Register with Google')}
                <button className="auth_button content" onClick={()=>changeTab('registerEmailForm')}>
                    <EmailIcon  style={{width : '18px',height : '18px',marginRight : '19px'}}/>
                    Register with Email
                </button>
                <button className="auth_button content" onClick={()=> changeTab('registerMobileForm')}>
                    <PhoneAndroidIcon  style={{width : '35px',height : '18px',marginRight : '10px'}}/>
                    Register with Mobile
                </button>
            </div>
            <span className="text_color_1 text-center fw-semibold fs-6 mb-4 content">Already have an account ? <span className="heading_color_3 fw-semibold content cursor" onClick={()=>changeTab('login')}>LogIn</span></span>
            <div className="d-flex flex-row justify-content-center align-items-center">
                <span className="text_color_1 text-center fw-semibold fs-6 mb-4 content">By registering, You agree to SpotLet's{' '}
                    <Link href="" className="brand_color fw-semibold content"
                    to={'/terms-and-conditions'} target="_blank"
                    >Terms & Conditions</Link>{' '}and{' '}
                    <Link className="brand_color fw-semibold content"
                    to={'/privacy-policy'} target="_blank"
                    >Privacy Policy</Link>
                </span>
            </div>
        </>
        )
    }
    const login = () => {
        return(
            <>
            <span className="main_text heading_color_1 mb-2">Login</span>
            <span className="sub_text heading_color_1 text-center mb-2">Welcome back !</span>
            {/* <span className="content text_color_1 text-center mb-2">To access our exclusive features and stay updated with the latest news, please register an account. It's quick and easy!</span> */}
            <div className="d-flex flex-column justify-content-between align-items-center w-100 mb-2">
                {google("Login with Google")}
                <button className="auth_button content" onClick={()=>changeTab('loginEmailForm')}>
                    <EmailIcon  style={{width : '18px',height : '18px',marginRight : '19px'}}/>
                    Login with Email
                </button>
                <button className="auth_button content"  onClick={()=>changeTab('loginMobileForm')}>
                    <PhoneAndroidIcon  style={{width : '35px',height : '18px',marginRight : '10px'}}/>
                    Login with Mobile
                </button>
            </div>
            <span className="text_color_1 text-center fw-semibold fs-6 mb-4 content">Don't have an account ? <span className="heading_color_3 fw-semibold content cursor" onClick={()=>changeTab('register')}>Register</span></span>
        </>
        )
    }
    const registerEmailForm = () => {
        return(
            <>
                <form className="d-flex flex-column w-100 mb-2" onSubmit={onSubmit}>
                    <span className="heading_color_3 content cursor" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                    <span className="main_text heading_color_1 mb-2 text-center">Register with Email</span>
                    <div className="mb-2">
                        <TextInput
                            id='auth_name'
                            label='Full Name'
                            name='name'
                            value={formData?.name || ""}
                            onChange={onChange}
                            placeholder='Enter full name'
                            validate={(value) => (value ? '' : 'Name is required')}
                            errorMessage={errors?.name}
                            required={true}
                            title="Please enter a full name"
                        />
                    </div>
                    <div className="mb-2">
                        <EmailInput
                            id='auth_email'
                            label='Email'
                            name='email'
                            value={formData?.email || ""}
                            onChange={onChange}
                            placeholder='example@example.com'
                            validate={(value) => (value ? '' : 'Email address is required')}
                            errorMessage={errors?.email}
                            required={true}
                            title="Please enter a valid email address."
                        />
                    </div>
                    <div className={errors?.password ? 'mb-4' : 'mb-2'}>
                        <PasswordInput
                            id='auth_password'
                            label='Password'
                            name='password'
                            value={formData?.password || ""}
                            onChange={onChange}
                            placeholder='Example@123'
                            validate={(value) => (value ? '' : 'Password is required')}
                            errorMessage={errors?.password}
                            required={true}
                            title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button">Get Code</button>
                </form>
                <div className="auth_moddle_line content text_color_1 text-center">Or</div>
                <div className="d-flex flex-row w-100">
                    {google("Google")}
                    <button className="auth_button content"  onClick={()=> changeTab('registerMobileForm')}>
                        <PhoneAndroidIcon  style={{width : '18px',height : '18px',marginRight : '20px'}}/>
                        Mobile
                    </button>
                </div>
                <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Already have an account ? <span className="heading_color_3 fw-semibold content cursor" onClick={()=>changeTab('login')}>LogIn</span></span>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <span className="text_color_1 text-center fw-semibold fs-6 mb-4 content">By registering, You agree to SpotLet's{' '}
                        <Link href="" className="brand_color fw-semibold content"
                        to={'/terms-and-conditions'} target="_blank"
                        >Terms & Conditions</Link>{' '}and{' '}
                        <Link className="brand_color fw-semibold content"
                        to={'/privacy-policy'} target="_blank"
                        >Privacy Policy</Link>
                    </span>
                </div>
            </>
        )
    }
    const registerMobileForm = () => {
        return(
            <>
                <form className="d-flex flex-column w-100 mb-2" onSubmit={onSubmit}>
                    <span className="heading_color_3 content cursor" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                    <span className="main_text heading_color_1 mb-2 text-center">Register with Mobile</span>
                    <div className="mb-2">
                        <TextInput
                            id='auth_name'
                            label='Full Name'
                            name='name'
                            value={formData?.name || ""}
                            onChange={onChange}
                            placeholder='Enter full name'
                            validate={(value) => (value ? '' : 'Name is required')}
                            errorMessage={errors?.name}
                            required={true}
                            title="Please enter a full name"
                        />
                    </div>
                    <div className="mb-2">
                        <MobileInput
                            id='modal_mobile'
                            label='Mobile'
                            name='mobile'
                            value={formData?.mobile || ""}
                            onChange={onChange}
                            placeholder='+910000000000'
                            validate={(value) => (value ? '' : 'Mobile number is required')}
                            errorMessage={errors?.mobile}
                            required={true}
                            title="Please enter a valid 10-digit Indian mobile number starting with +91"
                        />
                    </div>
                    <div className={errors?.password ? 'mb-4' : 'mb-2'}>
                        <PasswordInput
                            id='auth_password'
                            label='Password'
                            name='password'
                            value={formData?.password || ""}
                            onChange={onChange}
                            placeholder='Example@123'
                            validate={(value) => (value ? '' : 'Password is required')}
                            errorMessage={errors?.password}
                            required={true}
                            title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button">Get Code</button>
                </form>
                <div className="auth_moddle_line content text_color_1 text-center">Or</div>
                <div className="d-flex flex-row w-100">
                    {google("Google")}
                    <button className="auth_button content" onClick={()=>changeTab('registerEmailForm')}>
                        <EmailIcon  style={{width : '18px',height : '18px',marginRight : '25px'}}/>
                        Email
                    </button>
                </div>
                <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Already have an account ? <span className="heading_color_3 fw-semibold content cursor" onClick={()=>changeTab('login')}>LogIn</span></span>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <span className="text_color_1 text-center fw-semibold fs-6 mb-4 content">By registering, You agree to SpotLet's{' '}
                        <Link href="" className="brand_color fw-semibold content"
                        to={'/terms-and-conditions'} target="_blank"
                        >Terms & Conditions</Link>{' '}and{' '}
                        <Link className="brand_color fw-semibold content"
                        to={'/privacy-policy'} target="_blank"
                        >Privacy Policy</Link>
                    </span>
                </div>
            </>
        )
    }
    const loginMobileForm = () => {
        return(
            <>
                <form className="d-flex flex-column w-100 mb-2" onSubmit={onSubmit}>
                    <span className="heading_color_3 content cursor" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                    <span className="main_text heading_color_1 mb-2 text-center">Login with Mobile</span>
                    <div className="mb-2">
                        <MobileInput
                            id='modal_mobile'
                            label='Mobile'
                            name='mobile'
                            value={formData?.mobile || ""}
                            onChange={onChange}
                            placeholder='+910000000000'
                            validate={(value) => (value ? '' : 'Mobile number is required')}
                            errorMessage={errors?.mobile}
                            required={true}
                            title="Please enter a valid 10-digit Indian mobile number starting with +91"
                            disabled={loginMethod === "code" ? true : false}
                        />
                    </div>
                    { loginMethod === 'password' && <div className={errors?.password ? 'mb-4' : 'mb-2'}>
                        <PasswordInput
                            id='auth_password'
                            label='Password'
                            name='password'
                            value={formData?.password || ""}
                            onChange={onChange}
                            placeholder='Example@123'
                            validate={(value) => (value ? '' : 'Password is required')}
                            errorMessage={errors?.password}
                            required={true}
                            title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                        />
                    </div> }
                    { loginMethod === 'password' && <div className="mb-2 d-flex flx-row justify-content-end align-items-end">
                        <a className="content text_color_1 cursor" onClick={()=>changeTab('forgotMobile')}>Forgot Password ?</a>
                    </div>}
                    { (loginMethod === "both") && <div className = "auth_login_password_code_buttons_container" >
                        <button type='button' className="btn btn-primary sp_button auth_login_password_code_button" onClick={() => setLoginMethod("password")}>Login with Password</button>
                        <button type='button' className="btn btn-primary sp_button auth_login_password_code_button" onClick={onClickLoginWithCode}>Login with Code</button>
                    </div> }
                    { loginMethod === "code"  && <div className="mb-2">
                        <NumberInput
                            id='modal_otp'
                            label='Type your 4 digit security code'
                            name='otp'
                            value={formData?.otp || ""}
                            onChange={onChange}
                            placeholder='Enter 4 digit code'
                            validate={(value) => (value ? '' : 'Otp is required')}
                            errorMessage={errors?.otp}
                            required={true}
                            title="Please enter 4 digit security code"
                        />
                    </div>}
                    { loginMethod === "code" && <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Didn’t get the code ? You can  <span className={`${time === 0 ? "heading_color_3" : "text_color_1"} fw-semibold content cursor`} onClick={resendCode}>Resend</span> the code{time !== 0 && (<span className="sub_text heading_color_3"> in {time}</span>)}</span>}
                    { loginMethod !== "both" && <button type="submit" className="btn btn-primary sp_button">Login</button> }
                </form>
                <div className="auth_moddle_line content text_color_1 text-center">Or</div>
                <div className="d-flex flex-row w-100">
                    {google("Google")}
                    <button className="auth_button content" onClick={()=>changeTab('loginEmailForm')}>
                        <EmailIcon  style={{width : '18px',height : '18px',marginRight : '25px'}}/>
                        Email
                    </button>
                </div>
                <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Don't have an account ? <span className="heading_color_3 fw-semibold content cursor" onClick={()=>changeTab('register')}>Register</span></span>
            </>
        )
    }
    const loginEmailForm = () => {
        return(
            <>
                <form className="d-flex flex-column w-100 mb-2" onSubmit={onSubmit}>
                    <span className="heading_color_3 content cursor" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                    <span className="main_text heading_color_1 mb-2 text-center">Login with Email</span>
                    <div className="mb-2">
                        <EmailInput
                            id='auth_email'
                            label='Email'
                            name='email'
                            value={formData?.email || ""}
                            onChange={onChange}
                            placeholder='example@example.com'
                            validate={(value) => (value ? '' : 'Email address is required')}
                            errorMessage={errors?.email}
                            required={true}
                            title="Please enter a valid email address."
                            disabled={loginMethod === "code" ? true : false} 
                        />
                    </div>
                    { loginMethod === 'password' && <div className={errors?.password ? 'mb-4' : 'mb-2'}>
                        <PasswordInput
                            id='auth_password'
                            label='Password'
                            name='password'
                            value={formData?.password || ""}
                            onChange={onChange}
                            placeholder='Example@123'
                            validate={(value) => (value ? '' : 'Password is required')}
                            errorMessage={errors?.password}
                            required={true}
                            title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                        />
                    </div>}
                    { loginMethod === 'password' && <div className="mb-2 d-flex flx-row justify-content-end align-items-end">
                        <a className="content text_color_1 cursor" onClick={()=>changeTab('forgotEmail')}>Forgot Password ?</a>
                    </div>}
                    { (loginMethod === "both") && <div className = "auth_login_password_code_buttons_container" >
                        <button type='button' className="btn btn-primary sp_button auth_login_password_code_button" onClick={() => setLoginMethod("password")}>Login with Password</button>
                        <button type='button' className="btn btn-primary sp_button auth_login_password_code_button" onClick={onClickLoginWithCode}>Login with Code</button>
                    </div> }
                    { loginMethod === "code"  && <div className="mb-2">
                        <NumberInput
                            id='modal_otp'
                            label='Type your 4 digit security code'
                            name='otp'
                            value={formData?.otp || ""}
                            onChange={onChange}
                            placeholder='Enter 4 digit code'
                            validate={(value) => (value ? '' : 'Otp is required')}
                            errorMessage={errors?.otp}
                            required={true}
                            title="Please enter 4 digit security code"
                        />
                    </div>}
                    { loginMethod === "code" && <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Didn’t get the code ? You can  <span className={`${time === 0 ? "heading_color_3" : "text_color_1"} fw-semibold content cursor`} onClick={resendCode}>Resend</span> the code{time !== 0 && (<span className="sub_text heading_color_3"> in {time}</span>)}</span>}
                    { loginMethod !== "both" && <button type="submit" className="btn btn-primary sp_button">Login</button> }
                </form>
                <div className="auth_moddle_line content text_color_1 text-center">Or</div>
                <div className="d-flex flex-row w-100">
                    {google("Google")}
                    <button className="auth_button content"  onClick={()=> changeTab('loginMobileForm')}>
                        <PhoneAndroidIcon  style={{width : '18px',height : '18px',marginRight : '20px'}}/>
                        Mobile
                    </button>
                </div>
                <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Don't have an account ? <span className="heading_color_3 fw-semibold content cursor" onClick={()=>changeTab('register')}>Register</span></span>
            </>
        )
    }
    const registerOtp = () => {
        return(
            <>
                <span className="heading_color_3 content cursor align-self-start" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                <span className="main_text heading_color_1 mb-4 text-center">Two-Factor Verification</span>
                <span className="content text_color_1 text-center mb-4">Enter the verification code we sent to</span>
                <span className="content heading_color_1 text-center mb-4">{formData?.email ||formData?.mobile}{' '}<span className="content heading_color_3 cursor" onClick={onBackTab}>(Edit)</span></span>
                <form className="d-flex flex-column w-100 mb-4" onSubmit={onSubmit}>
                    <div className="mb-2">
                        <NumberInput
                            id='modal_otp'
                            label='Type your 4 digit security code'
                            name='otp'
                            value={formData?.otp || ""}
                            onChange={onChange}
                            placeholder='Enter 4 digit code'
                            validate={(value) => (value ? '' : 'Otp is required')}
                            errorMessage={errors?.otp}
                            required={true}
                            title="Please enter 4 digit security code"
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button mb-4">Register</button>
                    <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Didn’t get the code ? You can  <span className={`${time === 0 ? "heading_color_3" : "text_color_1"} fw-semibold content cursor`} onClick={resendCode}>Resend</span> the code{time !== 0 && (<span className="sub_text heading_color_3"> in {time}</span>)}</span>
                </form>
            </>
        )
    }
    const forgotEmail = () => {
        return(
            <>
                <span className="heading_color_3 content cursor align-self-start" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                <span className="main_text heading_color_1 mb-4 text-center">Forgot Password ?</span>
                <span className="content text_color_1 text-center mb-4">Enter your email to reset your password. You will receive a security code, which you will need to enter to complete the password reset process.</span>
                <form className="d-flex flex-column w-100 mb-4" onSubmit={onSubmit}>
                    <div className="mb-2">
                        <EmailInput
                            id='auth_email'
                            label='Email'
                            name='email'
                            value={formData?.email || ""}
                            onChange={onChange}
                            placeholder='example@example.com'
                            validate={(value) => (value ? '' : 'Email address is required')}
                            errorMessage={errors?.email}
                            required={true}
                            title="Please enter a valid email address."
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button">Get Code</button>
                </form>
            </>
        )
    }
    const forgotMobile = () => {
        return(
            <>
                <span className="heading_color_3 content cursor align-self-start" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                <span className="main_text heading_color_1 mb-4 text-center">Forgot Password ?</span>
                <span className="content text_color_1 text-center mb-4">Enter your mobile number to reset your password. You will receive a security code, which you will need to enter to complete the password reset process.</span>
                <form className="d-flex flex-column w-100 mb-4" onSubmit={onSubmit}>
                    <div className="mb-2">
                        <MobileInput
                            id='modal_mobile'
                            label='Mobile'
                            name='mobile'
                            value={formData?.mobile || ""}
                            onChange={onChange}
                            placeholder='+910000000000'
                            validate={(value) => (value ? '' : 'Mobile number is required')}
                            errorMessage={errors?.mobile}
                            required={true}
                            title="Please enter a valid 10-digit Indian mobile number starting with +91"
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button">Get Code</button>
                </form>
            </>
        )
    }
    const resetPassword = () => {
        return(
            <>
                <span className="heading_color_3 content cursor align-self-start" onClick={onBackTab}><ArrowBackIcon fontSize="small" /> Back</span>
                <span className="main_text heading_color_1 mb-2 text-center">Reset your Password</span>
                <span className="content text_color_1 text-center mb-2">Enter the verification code we sent to</span>
                <span className="content heading_color_1 text-center mb-2">{formData?.email ||formData?.mobile}{' '}<span className="content heading_color_3 cursor" onClick={onBackTab}>(Edit)</span></span>
                <form className="d-flex flex-column w-100 mb-2" onSubmit={onSubmit}>
                    <div className="mb-2">
                        <NumberInput
                            id='modal_otp'
                            label='Type your 4 digit security code'
                            name='otp'
                            value={formData?.otp || ""}
                            onChange={onChange}
                            placeholder='Enter 4 digit code'
                            validate={(value) => (value ? '' : 'Otp is required')}
                            errorMessage={errors?.otp}
                            required={true}
                            title="Please enter 4 digit security code"
                        />
                    </div>
                    <div className="mb-2">
                        <PasswordInput
                            id='auth_password'
                            label='New Password'
                            name='password'
                            value={formData?.password || ""}
                            onChange={onChange}
                            placeholder='Example@123'
                            validate={(value) => (value ? '' : 'Password is required')}
                            errorMessage={errors?.password}
                            required={true}
                            title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                        />
                    </div>
                    <div className="mb-2">
                        <PasswordInput
                            id='auth_password'
                            label='Confirm Password'
                            name='confirm_password'
                            value={formData?.confirm_password || ""}
                            onChange={onChange}
                            placeholder='Example@123'
                            validate={(value) => (value ? '' : 'Confirm password is required')}
                            errorMessage={errors?.confirm_password}
                            required={true}
                            title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button mb-2">Reset Password</button>
                    <span className="text_color_1 text-center fw-semibold fs-6 mb-2 content">Didn’t get the code ? You can  <span className={`${time === 0 ? "heading_color_3" : "text_color_1"} fw-semibold content cursor`} onClick={resendCode}>Resend</span> the code{time !== 0 && (<span className="sub_text heading_color_3"> in {time}</span>)}</span>
                </form>
            </>
        )
    }
    //component end
    return(
        <>
            <Link href="" className="heading_color_1 sub_text m-2 link"to={'/'} target="_blank">
                <img alt="spotlet logo" className='auth_logo auth_mobile_show_logo' src={logo}/>
            </Link>
            {tab==='register' && register()}
            {tab==='login' && login()}
            {tab==='registerEmailForm' && registerEmailForm()}
            {tab==='registerMobileForm' && registerMobileForm()}
            {tab==='loginMobileForm' && loginMobileForm()}
            {tab==='loginEmailForm' && loginEmailForm()}
            {tab==='registerOtp' && registerOtp()}
            {tab==='forgotEmail' && forgotEmail()}
            {tab==='forgotMobile' && forgotMobile()}
            {tab==='resetPassword' && resetPassword()}
        </>
    )
}
export default Autheniticator;