import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FullPageLoader = () => {
    return(
        <div>
        <Backdrop
            sx={{ color: '#EA4335', zIndex: (theme) => theme.zIndex.drawer+1 }}
            open={true}
        >
            <CircularProgress color="inherit" size = {100} thickness = {2}/>
        </Backdrop>
        </div>
    )
}
export default FullPageLoader;