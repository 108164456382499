
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const ToolTip = ({ content,children }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          {content || 'This is tool tip'}
        </Tooltip>
    );
    return(
        <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
        {children || <InfoOutlinedIcon sx={{fontSize : '16px'}} className='cursor'/>}
        </OverlayTrigger>
    )
}
export default ToolTip;