import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PropertyTile from "../PropertyTile/PropertyTile";

const responsive = {
    largeDesktop: {
    breakpoint: { max: 3000, min: 1520 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
    },
    desktop: {
    breakpoint: { max: 1520, min: 1230 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
    breakpoint: { max: 1230, min: 825 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
    breakpoint: { max: 825, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
    }
};

const PropertiesCarousal = ({data,settings}) => {
    console.log(settings)
    return(
        <div className="d-flex flex-row justify-content-center align-items-center w-100 mt-4">
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={settings?.autoPlay === 'active' ? true : false}
                autoPlaySpeed={parseInt(settings?.interval) * 1000}
                keyBoardControl={false}
                customTransition="transform 300ms ease-in-out"
                transitionDuration={500}
                containerClass="carousel-container home_unique_carousel w-100" 
                removeArrowOnDeviceType={[]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                >
                    {data?.map((item, index) => {
                        return(
                            <div key={index}  className="d-flex flex-row justify-content-center align-items-center w-100">
                                <PropertyTile data = {item} />
                            </div>
                        )
                    })}
                </Carousel>
        </div>
    )
}
export default PropertiesCarousal;