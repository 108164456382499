import React from 'react';
import { useState,useEffect } from 'react';
import "./EditProfile.css"
import { useAuth } from '../../Utils/Auth';
import { useUser } from '../../Utils/Auth';
import TextInput from '../../Inputs/TextInput';
import EmailInput from '../../Inputs/EmailInput';
import MobileInput from '../../Inputs/MobileInput';
import PasswordInput from '../../Inputs/PasswordInput';
import addImage from '../../Assets/Images/EditProfile/add-image.svg'
import man from '../../Assets/Images/EditProfile/man.png'
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { updateUser,uploadPics,updateUserPassword } from '../../Apis/Api';
import Header from '../../Components/Header/Header';
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import VerifyEmail from '../../Modals/VerifyEmail';
import VerifyMobile from '../../Modals/VerifyMobile';
import {deActivateUser} from '../../Apis/Api';
const EditProfile = () => {
    const {notify, triggerLoader, confirm, closeConfirmToast} = useAuth()
    const {user,getUser} = useUser()
    console.log('user', user);
    // const [showActiveLisitngModel, setShowActiveLisitngModel] = useState(false);
    // const [showDeactivateModel, setShowDeactivateModel] = useState(false);
    // const [showConfirmationModel2, setShowConfirmationModel2] = useState(false);
    // const [confirmationTitle2, setConfirmationTitle2] = useState("");
    // const [confirmationBody2, setConfirmationBody2] = useState("");
    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [formData,setFormData] = useState()
    const [formData2,setFormData2] = useState({})
    const [errors, setErrors] = useState({});
    const [errors2, setErrors2] = useState({});
    const [showVerifyEmail,setShowVerifyEmail] = useState(false)
    const [showVerifyMobile,setShowVerifyMobile] = useState(false)
    const toggleEmail = () => {
        setShowVerifyEmail(!showVerifyEmail);
    };
    const toggleMobile = () => {
        setShowVerifyMobile(!showVerifyMobile);
    };
    const handleEmailSuccess = () => {
        setShowVerifyEmail(false)
        getUser()
    }
    const handleMobileuccess = () => {
        setShowVerifyMobile(false)
        getUser()
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        setErrors({ ...errors, [name]: '' });
        // if(value){
        //     setErrors({ ...errors, [name]: '' });
        // }else{
        //     setErrors({...errors,[name] : `This field is required`})
        // }
    }
    const onChange2 = (e) => {
        const {name,value} = e.target
        setFormData2({
            ...formData2,
            [name] : value,
        })
        if(value){
            setErrors2({ ...errors2, [name]: '' });
        }else{
            setErrors2({...errors2,[name] : `This field is required`})
        }
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return regex.test(email) ? '' : 'Invalid email format';
    };
    const validateIndianMobile = (mobile) => {
        const regex = /^\+91[6-9]\d{9}$/;
        return regex.test(mobile) ? '' : 'Invalid mobile number';
    };
    function validatePassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
        return regex.test(password) ? '' : 'Invalid password';
    }
    function validateConfirmPassword(password) {
        return formData2.new_password === password ? '' : ' New password and confirm password are not the same';
    }
    const validateName = (name) => {
        const regex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
        const trimmedName = name.trim();  // Removes leading/trailing spaces
        if (!trimmedName) {
            return "Name is required";
        } else if (!regex.test(trimmedName)) {
            return 'Name can only contain letters and spaces';
        } else if (trimmedName.length < 2) {
            return 'Name should be minimum 2 letters';
        }
        return '';
    };
    const validateForm = async () => {
        let newErrors = {
            name: formData.name ? validateName(formData?.name) : 'Name is required',
            email: formData.email ? validateEmail(formData.email) : '',
            mobile: formData.mobile ? validateIndianMobile(formData.mobile) : '',
        };
        console.log(newErrors)
        setErrors(newErrors);
        // Return false if there are errors
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById('edit_profile_'+ key);
                if (inputElement) {
                    inputElement.scrollIntoView({ behavior: 'smooth' });
                }
                return false
            }
        }
        return true
    };
    const validateForm2 = async () => {
        let newErrors = {
            current_password: formData2.current_password ? validatePassword(formData2.current_password) : 'Current password is required',
            new_password: formData2.new_password ? validatePassword(formData2.new_password) : 'New password is required',
            confirm_password: formData2.confirm_password ? validateConfirmPassword(formData2.confirm_password) : 'Confirm password is required',
        };
        console.log(newErrors)
        setErrors2(newErrors);
        // Return false if there are errors
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById('edit_profile_'+ key);
                if (inputElement) {
                    inputElement.scrollIntoView({ behavior: 'smooth' });
                }
                return false
            }
        }
        return true
    };
    const onSubmit = async (e) => {
        e.preventDefault()
        if (! await validateForm()) {
            return null
        }
        if(formData?.email && !user?.personalInfo?.emailVerified){
            notify('Please verify email adrdress before submitting','error')
            return null
        }
        if(formData?.mobile && !user?.personalInfo?.mobileVerified){
            notify('Please verify mobile number before submitting','error')
            return null
        }
        triggerLoader(true)
        try{
            let imageRes
            if(image){
                const imageData = new FormData();
                imageData.append('image', image);
                imageRes = await uploadPics(imageData)
            }
            if(imageRes) formData.image = imageRes?.data.imageUrl
            const resp = await updateUser(formData)
            setImage(null)
            notify(resp?.data?.message,'success')
            getUser()
        }catch(error){
            console.log(error)
            notify(error?.response?.data?.message,'error')
        }
        triggerLoader(false) 
    }
    const onSubmit2 = async (e) => {
        e.preventDefault()
        if (! await validateForm2()) {
            return null
        }
        triggerLoader(true)
        try{
            const resp = await updateUserPassword(formData2)
            notify(resp?.data?.message,'success')
            setFormData2({})
        }catch(error){
            console.log(error)
            notify(error?.response?.data?.message,'error')
        }
        triggerLoader(false) 
    }
    const onUploadImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        }
    }
    const verifyEmail = () => {
        if(!formData?.email){
            setErrors({...errors,email : 'Email address is required'})
            return null
        }
        const error = validateEmail(formData?.email)
        if(error){
            setErrors({...errors,email : error})
            return null
        }
        toggleEmail()
    }
    const verifyMobile = () => {
        if(!formData?.mobile){
            setErrors({...errors,mobile : 'Mobile number is required'})
            return null
        }
        const error = validateIndianMobile(formData?.mobile)
        console.log(error)
        if(error){
            setErrors({...errors,mobile : error})
            return null
        }
        toggleMobile()
    }
    const onDeActiveNew = async () => {
        try {
            const response = await deActivateUser();
            notify(response?.data?.message, "success");
            console.log("response", response);
        } catch (error) {
          console.log(error?.response?.data?.message);
          const errorMsg = error?.response?.data?.message ? error?.response?.data?.message : "Some Error occured"
          notify(errorMsg, "error");
        }
    }

    useEffect(()=>{
        console.log(user)
        setFormData({
            name : user?.personalInfo?.fullName,
            email : user?.personalInfo?.email,
            mobile : user?.personalInfo?.mobile,
            image : user?.personalInfo?.profile_pic,
        })
    },[user])
    return(
        <div className='Spotlet'>
            <Header/>
            <div className='spotlet_main d-flex flex-row'>
                <LeftSideBar/>
                <div className='edit_profile'>
                   <span className='main_text heading_color_1'>Edit Profile</span>
                   <form className='edit_profile_form_section mt-4 w-100' onSubmit={onSubmit}>
                        <div className='edit_profile_image_section' style={{backgroundImage : `url(${previewUrl || formData?.image || man})`}}>
                        </div>
                        <input
                            className='edit_profile_image_input'
                            id='imageUpload'
                            type="file"
                            accept=".webp, .webp, .jpeg"
                            onChange={onUploadImage}
                        />
                        <label className='edit_profile_image_label' htmlFor="imageUpload">
                            <img src={addImage} />
                        </label>
                        <div className='edit_profile_form_section_item w-100'>
                            <TextInput
                                id='edit_profile_name'
                                label='Full Name'
                                name='name'
                                value={formData?.name || ""}
                                onChange={onChange}
                                placeholder='Enter full name'
                                validate={(value) => (value ? '' : 'Name is required')}
                                errorMessage={errors?.name}
                                required={true}
                                title="Please enter a full name"
                            />
                        </div>
                        <div className='edit_profile_wrap_section'>
                            <div className="edit_profile_form_section_item w-100">
                                <EmailInput
                                    id='edit_profile_email'
                                    label='Email'
                                    name='email'
                                    value={formData?.email || ""}
                                    onChange={onChange}
                                    placeholder='example@example.com'
                                    // validate={(value) => (value ? '' : 'Email address is required')}
                                    errorMessage={errors?.email}
                                    disabled={user?.personalInfo?.emailVerified}
                                />
                                {user?.personalInfo?.emailVerified && <p className='edit_profile_verify content'><VerifiedIcon/>Verified</p>}
                                {!user?.personalInfo?.emailVerified && <p className='brand_color content cursor' style={{marginTop : errors?.email ? '0px' : '-18px'}} onClick={verifyEmail}><NewReleasesIcon/>Verify now</p>}
                            </div>
                            <div className="edit_profile_form_section_item w-100">
                                <MobileInput
                                    id='edit_profile_mobile'
                                    label='Mobile'
                                    name='mobile'
                                    value={formData?.mobile || ""}
                                    onChange={onChange}
                                    placeholder='+910000000000'
                                    // validate={(value) => (value ? '' : 'Mobile number is required')}
                                    errorMessage={errors?.mobile}
                                    disabled={user?.personalInfo?.mobileVerified}
                                />
                                {user?.personalInfo?.mobileVerified && <p className='edit_profile_verify content'><VerifiedIcon/>Verified</p>}
                                {!user?.personalInfo?.mobileVerified && <p className='brand_color content cursor' style={{marginTop : errors?.mobile ? '0px' : '-18px'}} onClick={verifyMobile}><NewReleasesIcon/>Verify now</p>}
                            </div>
                        </div>
                        <button className='btn btn-primary sp_button edit_profile_form_section_item w-100'>Submit</button>
                   </form>
                   {!user?.personalInfo?.googleLogin && (
                        <>
                            <hr className='w-100'/>
                            <span className='main_text heading_color_1'>Change Password</span>
                            <form className='edit_profile_form_section mt-4 w-100' onSubmit={onSubmit2}>
                                    <div className='edit_profile_form_section_item w-100'>
                                        <PasswordInput
                                            id='edit_profile_current_password'
                                            label='Current Password'
                                            name='current_password'
                                            value={formData2?.current_password || ""}
                                            onChange={onChange2}
                                            placeholder='Example@123'
                                            validate={(value) => (value ? '' : 'Current password is required')}
                                            errorMessage={errors2?.current_password}
                                            required={true}
                                            title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                                        />
                                    </div>
                                    <div className='edit_profile_wrap_section'>
                                        <div className='edit_profile_form_section_item w-100'>
                                            <PasswordInput
                                                id='edit_profile_new_password'
                                                label='New Password'
                                                name='new_password'
                                                value={formData2?.new_password || ""}
                                                onChange={onChange2}
                                                placeholder='Example@123'
                                                validate={(value) => (value ? '' : 'New password is required')}
                                                errorMessage={errors2?.new_password}
                                                required={true}
                                                title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                                            />
                                        </div>
                                        <div className='edit_profile_form_section_item w-100'>
                                            <PasswordInput
                                                id='edit_profile_confirm_password'
                                                label='Confirm Password'
                                                name='confirm_password'
                                                value={formData2?.confirm_password || ""}
                                                onChange={onChange2}
                                                placeholder='Example@123'
                                                validate={(value) => (value ? '' : 'Confirm password is required')}
                                                errorMessage={errors2?.confirm_password}
                                                required={true}
                                                title="Password must contain 1 capital letter, 1 small letter, 1 special character, 1 digit, 8-16 characters."
                                            />
                                        </div>
                                    </div>
                                    <button className='btn btn-primary sp_button edit_profile_form_section_item w-100'>Submit</button>
                            </form>
                            <hr className='w-100'/>
                            <div className="col-lg-10">
                            <div className="deactive ">
                                <div className='d-flex flex-column'>
                                    <span className='sub_text'>Deactivate Your Account</span>
                                    <span className='content text_color_3 mt-2'>
                                    It is possible to deactivate your account, and you can reactivate it at any time. Please make sure this is the action you would like to take.
                                    </span>
                                </div>
                              <button
                                className="mt-2 btn btn-outline-danger"
                                onClick={ () => {
                                    closeConfirmToast();
                                    confirm("Are you sure you want to De-activate the Account ?", "De-activate", () => onDeActiveNew());
                                }}
                              >
                                Deactivate Account
                              </button>
                            </div>
                          </div>
                        </>
                   )}
                </div>
            </div>
            {showVerifyEmail && <VerifyEmail show={showVerifyEmail} onHide={toggleEmail} onSuccess={handleEmailSuccess} email={formData?.email}/>}
            {showVerifyMobile && <VerifyMobile show={showVerifyMobile} onHide={toggleMobile} onSuccess={handleMobileuccess} mobile={formData?.mobile}/>}
        </div>
    )
}
export default EditProfile;