import React,{ useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './PropertyTile.css'
import fillHeart from "../../Assets/Images/PropertyTile/fill-heart.svg";
import borderHeart from "../../Assets/Images/PropertyTile/border-heart.svg";
import film from "../../Assets/Images/PropertyTile/film.svg";
import corp from "../../Assets/Images/PropertyTile/eventNew.svg";
import individual from "../../Assets/Images/PropertyTile/stayNew.svg";
import location from "../../Assets/Images/PropertyTile/location.svg";
import { updateFavourites } from "../../Apis/Api";
import { useAuth } from "../../Utils/Auth";
import { useUser } from "../../Utils/Auth";
import Auth from '../../Modals/Auth';

const PropertyTile = ({data,eventType,eventSub,plateType,refresh}) => {
    const {notify} = useAuth()
    const {user} = useUser()
    const [compoUser,setCompoUser] = useState({})
    const [price, setPrice] = useState({});
    const [nonVegPrice,setNonVegPrice] = useState('');
    const [showAuth,setShowAuth] = useState(false)
    const url = data?.locationPageUrl ? data.locationPageUrl : data?.location_id
    let tag = ''
    switch (data?.subscription_id) {
        case 'Premium':
            tag = 'Highly recommended'
            break;
        case 'Standard':
            tag = 'Recommended'
            break;
        case 'Basic':
            tag = ''
    
        default:
            tag = '';
            break;
    }
    const handleFavourite = async () => {
        try{
            if(!user){
                setShowAuth(!showAuth)
                return null
            }
            const resp = await updateFavourites(data?.location_id)
            setCompoUser(resp?.data?.updatedUser)
            notify(resp?.data?.message,'success')
            if(refresh) refresh()
        }catch(error){
            console.log(error)
            notify(error?.response?.data?.message,'error')

        }
    }
    const  formatNumber = (number) => {
        const suffixes = ["", "k", "M", "B", "T"];
        if (number < 1000) {
          return number.toString();
        }
        const suffixNum = Math.floor(("" + number).length / 3);
        const shortValue = number / Math.pow(1000, suffixNum);
        return shortValue + suffixes[suffixNum];
    }
    const toggleAuth = () => {
        setShowAuth(!showAuth)
    }
    const handleSuccess = () => {
        setShowAuth(!showAuth)
        handleFavourite()
    }
    // Calculation of tile price start
    const findMinPriceObject = (priceArray) => {
        // Handle the case where the array has only one object
        if (priceArray.length === 1) {
          return setPrice(priceArray[0]);
        }
      
        // Use reduce to find the object with the minimum price
        const temp = priceArray.reduce((min, current) => {
          const minPrice = typeof min.price === 'string' ? parseFloat(min.price) : min.price;
          const currentPrice = typeof current.price === 'string' ? parseFloat(current.price) : current.price;
          
          return currentPrice < minPrice ? current : min;
        }, priceArray[0]);
        return setPrice(temp)
    };
    const calculateMinFilmPrice = () => {
        let price = []
        if (data?.pricing?.film?.["24Hours"]?.available) {
          const priceObj = {
            type : 'film',
            tag : 'day',
            price : data?.pricing?.film?.["24Hours"]?.price,
          }
          price.push(priceObj)
        }
    
        if (data?.pricing?.film?.["12Hours"]?.available) {
          const priceObj = {
            type : 'film',
            tag : 'half day',
            price : data?.pricing?.film?.["12Hours"]?.price,
          }
          price.push(priceObj)
        }
    
        if (data?.pricing?.film?.["perHour"]?.available) {
          const priceObj = {
            type : 'film',
            tag : 'hour',
            price : data?.pricing?.film?.["perHour"]?.price,
          }
          price.push(priceObj)
        }
        findMinPriceObject(price)
      }
    const calculateMinTvPrice = () => {
      let price = []
      if (data?.pricing?.tv?.["24Hours"]?.available) {
        const priceObj = {
          type : 'tv',
          tag : 'day',
          price : data?.pricing?.tv?.["24Hours"]?.price,
        }
        price.push(priceObj)
      }
  
      if (data?.pricing?.tv?.["12Hours"]?.available) {
        const priceObj = {
          type : 'tv',
          tag : 'half day',
          price : data?.pricing?.tv?.["12Hours"]?.price,
        }
        price.push(priceObj)
      }
  
      if (data?.pricing?.tv?.["perHour"]?.available) {
        const priceObj = {
          type : 'tv',
          tag : 'hour',
          price : data?.pricing?.tv?.["perHour"]?.price,
        }
        price.push(priceObj)
      }
      findMinPriceObject(price)
    }
    const calculateEventPrice = () => {
      let price = []
      if(eventSub === 'venue'){
        const priceObj = {
          type : 'event',
          tag : `${data?.pricing?.event?.["venue"]?.minimumBookingHours} hours`,
          price : data?.pricing?.event?.["venue"]?.price,
        }
        price.push(priceObj)
        findMinPriceObject(price)
        return null
      }
      if(eventSub === 'perHour'){
        const priceObj = {
          type : 'event',
          tag : `hour`,
          price : data?.pricing?.event?.["perHour"]?.price,
        }
        price.push(priceObj)
        findMinPriceObject(price)
        return null
      }
      if(eventSub === 'perPlate'){
        const priceObj = {
          type : 'event',
          tag : `veg`,
          price : data?.pricing?.event?.["perPlate"]?.vegPrice,
        }
        price.push(priceObj)
        findMinPriceObject(price)
        if(plateType !== 'pureVeg' && (!data?.pricing?.event?.pureVeg) ){
          setNonVegPrice({
            type : 'event',
            tag : `non veg`,
            price : data?.pricing?.event?.["perPlate"]?.nonVegPrice,
          })
        }
        return null
      }
      if (data?.pricing?.event?.["venue"]?.available) {
        const priceObj = {
          type : 'event',
          tag : `${data?.pricing?.event?.["venue"]?.minimumBookingHours} hours`,
          price : data?.pricing?.event?.["venue"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.event?.["perHour"]?.available) {
        const priceObj = {
          type : 'event',
          tag : 'hour',
          price : data?.pricing?.event?.["perHour"]?.price,
        }
        price.push(priceObj)
      }
      if (!(data?.pricing?.event?.["venue"]?.available || data?.pricing?.event?.["perHour"]?.available) && data?.pricing?.event?.["perPlate"]?.available) {
        const priceObj = {
          type : 'event',
          tag : 'plate',
          price : data?.pricing?.event?.["perPlate"]?.vegPrice,
        }
        price.push(priceObj)
        if(data?.pricing?.event?.perPlate?.nonVeg && (!(data?.pricing?.event?.pureVeg)) ){
          setNonVegPrice({
            type : 'event',
            tag : `non veg`,
            price : data?.pricing?.event?.["perPlate"]?.nonVegPrice,
          })
        }
      }
      findMinPriceObject(price)
    }
    const calculateStacationPrice = () => {
      let price = []
      if(data?.pricing?.stay?.available){
        const priceObj = {
          type : 'staycation',
          tag : 'night',
          price : data?.pricing?.stay?.weekdayPrice,
        }
        price.push(priceObj)
      }
      findMinPriceObject(price)
    }
    const getDefaultData = () => {
      let price = []
      if (data?.pricing?.film?.available && data?.pricing?.film?.["24Hours"]?.available) {
        const priceObj = {
          type : 'film',
          tag : 'day',
          price : data?.pricing?.film?.["24Hours"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.film?.available && data?.pricing?.film?.["12Hours"]?.available) {
        const priceObj = {
          type : 'film',
          tag : 'half day',
          price : data?.pricing?.film?.["12Hours"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.film?.available && data?.pricing?.film?.["perHour"]?.available) {
        const priceObj = {
          type : 'film',
          tag : 'hour',
          price : data?.pricing?.film?.["perHour"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.tv?.available && data?.pricing?.tv?.["24Hours"]?.available) {
        const priceObj = {
          type : 'tv',
          tag : 'day',
          price : data?.pricing?.tv?.["24Hours"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.tv?.available && data?.pricing?.tv?.["12Hours"]?.available) {
        const priceObj = {
          type : 'tv',
          tag : 'half day',
          price : data?.pricing?.tv?.["12Hours"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.tv?.available && data?.pricing?.tv?.["perHour"]?.available) {
        const priceObj = {
          type : 'tv',
          tag : 'hour',
          price : data?.pricing?.tv?.["perHour"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.event?.available && data?.pricing?.event?.["venue"]?.available) {
        const priceObj = {
          type : 'event',
          tag : '8 hours',
          price : data?.pricing?.event?.["venue"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.event?.available && data?.pricing?.event?.["perHour"]?.available) {
        const priceObj = {
          type : 'event',
          tag : 'hour',
          price : data?.pricing?.event?.["perHour"]?.price,
        }
        price.push(priceObj)
      }
      if (data?.pricing?.event?.available && data?.pricing?.event?.["perPlate"]?.available) {
        const priceObj = {
          type : 'event',
          tag : 'plate',
          price : data?.pricing?.event?.["perPlate"]?.vegPrice,
        }
        price.push(priceObj)
      }
      if(data?.pricing?.stay?.available){
        const priceObj = {
          type : 'staycation',
          tag : 'night',
          price : data?.pricing?.stay?.weekdayPrice,
        }
        price.push(priceObj)
      }
      findMinPriceObject(price)
    }
    const getRequiredData = () => {
        switch (eventType) {
          case 'film':
            calculateMinFilmPrice()
            break;
          case 'tv':
            calculateMinTvPrice()
            break;
          case 'event':
            calculateEventPrice()
            break;
          case 'stay':
            calculateStacationPrice()
            break;
          default:
            getDefaultData()
            break;
        }
      }
    function setLocalStorageWithExpiry() {
      const now = new Date();
      const item = {
          price,
          nonVegPrice,
          expiry: now.getTime() + 5 * 60 * 1000,
      };
      console.log(item)
      localStorage.setItem(`${data?.location_id}`, JSON.stringify(item));
    }
    const handleNav = () => {
        setLocalStorageWithExpiry();
        window.open(`${window.location.origin}/property/${url}`, '_blank')
    }
    useEffect(()=> {
        setNonVegPrice('')
        getRequiredData()
      },[data,eventType,eventSub,plateType])
    // Calculation of tile price end
    useEffect(()=>{
        setCompoUser(user)
    },[user])


    return(
        <div className="sp_tile_container cursor">
            <div className='sp_tile_images_section'>
                <ImageGallery
                    // items={data?.images?.map((image) => ({original : image?.image_320 ? image?.image_320 : image?.image,loading : 'lazy'}))}
                    items={data?.images?.map((image) => ({original : image?.image_320 ? image?.image_320 : image?.image }))}
                    disableKeyDown= {true}
                    showPlayButton={false}
                    showIndex={false}
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showBullets={true}
                    stopPropagation={true}
                    lazyLoad={true}
                    onClick={handleNav}
                    // onClick={navigatePropertyPage}
                    bulletClass='bulletClass'
                // infinite = {false}
                />
            </div>
            <div className="sp_tile_badge_section" style={{justifyContent : tag ? 'space-between' : "flex-end"}}>
                {tag && <span className="sp_tile_gold_badge" onClick={handleNav}>{tag}</span>}
                <img onClick={handleFavourite} className="sp_tile_event_icons cursor align-self-end" src={compoUser?.favourites?.includes(data?.location_id) ? fillHeart : borderHeart} alt="favourite icon"/>
            </div>
            <div className="sp_tile_event_container" onClick={handleNav}>
                {(data?.pricing?.film?.available || data?.pricing?.tv?.available)  && <img className="sp_tile_event_icons" loading="lazy" src={film} alt='fiim icon'/>}
                {data?.pricing?.event?.available && <img className="sp_tile_event_icons" loading="lazy" src={corp} alt='event icon'/>}
                {data?.pricing?.stay?.available && <img className="sp_tile_event_icons" loading="lazy" src={individual} alt='stay icon'/>}
            </div>
            <div className="sp_tile_text_container" onClick={handleNav}>
                <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                    <span className="sub_text heading_color_1">#{data?.location_id}</span>
                    <span className="content">{data?.propertyViews ? formatNumber(data?.propertyViews) : 0} Views</span>
                </div>
                {price && 
                    <span className="sp_tile_starts_from brand_color">Starts from
                            <span className="content brand_color font-weight-bold ml-1">INR. {price?.["price"]}<span className="content text_color_1 font-weight-normal">/{price?.["tag"]}</span></span>
                            {nonVegPrice && 
                            <>
                              <span className="content text_color_1 font-weight-normal">{' '}&</span>
                              <span className="content brand_color font-weight-bold ml-1">{nonVegPrice?.["price"]}
                              <span className="content text_color_1 font-weight-normal">/{nonVegPrice?.["tag"]}</span>
                              </span>
                            </>
                            }
                    </span>
                }
                <div className="d-flex flex-column  mb-2">
                    <span className="content heading_color_1 align-self-end">{data?.location_type}</span>
                    <div className="d-flex flex-row align-items-center align-self-end flex-wrap" >
                        <span className="content mr-1 d-flex flex-row align-items-center"> <img className="sp_location_icon" src={location} alt='locations icon'/> {data?.address?.area ? data?.address?.area?.length <= 10 ? data?.address?.area : `${data?.address?.area?.slice(0,11)}...`  : data?.address?.city }, </span>
                        <span className="content"> { data?.address?.city } </span>
                    </div>
                </div>
            </div>
            {showAuth && <Auth show={showAuth} onHide={toggleAuth} onSuccess={handleSuccess}/>}
        </div>
    )
}
export default React.memo(PropertyTile);