import { useLocation } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import logo from "../../Assets/Images/Notifications/Favicon.ico"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState,useEffect,useRef } from "react";
import { getNotifications, markNotificationAsRead,deleteNotification } from "../../Apis/Api";
import { useAuth, useUser } from '../../Utils/Auth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import NotificationPopupModal from '../../Modals/NotificationPopupModal';
import "./Notifications.css"



const sortOptions = [
    'Latest first',
    'Oldest first',
];

const Notifications = () => {
    const { confirm, triggerLoader, closeConfirmToast } = useAuth();
    const {user} = useUser();
    const location = useLocation();
    const { unread, id } = location.state || {};
    const tempType = unread ? 'Unread' : 'Total'
    const [requestedId,setRequestedId] = useState(id)
    const [notifications,setNotifications] = useState([])
    const [totalNotificationCount,setTotalNotificationCount] = useState(null)
    const [readNotificationCount,setReadNotificationCount] = useState(null)
    const [unreadNotificationCount,setUnreadNotificationCount] = useState(null)
    const [type,setType] = useState(tempType)
    const [search,setSearch] = useState('')
    const [sort,setSort] = useState(-1)
    const [selected,setSelected] = useState([])
    const [openedNotification,setOpenedNotificaiton] = useState('')
    const [notificationOpen,setNotificationOpen] = useState(false)
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    function findObjectById(data, idToCheck) {
        for (const key in data) {
          const found = data[key].find(item => item.id === idToCheck);
          if (found) {
            return found;
          }
        }
        return null; // Return null if not found
      }

    const getData = async () => {
        triggerLoader(true)
        if (user?._id){
            try{
                const resp = await getNotifications({id : user?._id,type,sort,search})
                setNotifications(resp?.data?.groupedNotifications)
                setTotalNotificationCount(resp?.data?.total)
                setReadNotificationCount(resp?.data?.read)
                setUnreadNotificationCount(resp?.data?.unread)
                if(requestedId){
                    const result = findObjectById(resp?.data?.groupedNotifications, requestedId);
                    setNotificationOpen(true)
                    setOpenedNotificaiton(result)
                    setRequestedId(null)
                    if(!result?.read){
                        triggerLoader(true)
                        try{
                            await markNotificationAsRead({id : user?._id, notif : [result?.id]});
                            getData()
                        }catch(error){
                            console.log(error)
                        }
                        triggerLoader(false)
                    }
                }
            }catch(error){
                console.log(error)
            }
        }
        triggerLoader(false)
    }
    const onChangeType = (value) => {
        setType(value)
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
    }
    const onCheck = (event,id) => {
        event.stopPropagation()
        if(selected.includes(id)){
            const data = selected.filter(item => item !== id);
            setSelected(data)
        }else{
            setSelected([...selected,id])
        }
    }
    const handleOpenNotification = async (e,data) => {
        setOpenedNotificaiton(data)
        setNotificationOpen(true)
        if(window.innerWidth < 992){
            setShowNotificationModal(true)
        }
        if(!data?.read){
            triggerLoader(true)
            try{
                await markNotificationAsRead({id : user?._id, notif : [data?.id]});
                getData()
            }catch(error){
                console.log(error)
            }
            triggerLoader(false)
        }
    }
    const getConfirmation = (item,text,event,id) => {
        if(event) event.stopPropagation();
        closeConfirmToast();
        confirm(text, "OK",  () => {
            switch (item) {
                case 'markAll':
                    markAll()
                    setSelectAll(false);
                    break;
                    case 'markSelected':
                    markSelected()
                    setSelectAll(false);
                    break;
                case 'deleteSelected':
                    deleteSelected();
                    setSelectAll(false);
                    break;
                case 'deleteAll':
                    deleteAll();
                    setSelectAll(false);
                    break;
                case 'deleteSingleNotification':
                    deleteSingleNotification(id);
                    setSelectAll(false);
                    break;
                default:
                    break;
            }
        } );
    }
    const markAll = async () => {
        triggerLoader(true)
        try{
            await markNotificationAsRead({id : user?._id,markAll : true});
            setSelected([])
            getData()
        }catch(error){
            console.log(error)
        }
        triggerLoader(false)
    }
    const markSelected = async () => {
            triggerLoader(true)
            try{
                await markNotificationAsRead({id : user?._id,notif : selected});
                setSelected([])
                getData()
            }catch(error){
                console.log(error)
            }
            triggerLoader(false)
    }
    const deleteSelected = async () => {
            triggerLoader(true)
            try{
                await deleteNotification({id : user?._id,notif : selected});
                setSelected([])
                getData()
            }catch(error){
                console.log(error)
            }
            triggerLoader(false)
    }
    const deleteAll = async () => {
        triggerLoader(true)
        try{
            await markNotificationAsRead({id : user?._id,markAll : true});
            setSelected([])
            getData()
        }catch(error){
            console.log(error)
        }
        triggerLoader(false)
    }
    const deleteSingleNotification = async (id) => {
        triggerLoader(true)
        try{
            await deleteNotification({id : user?._id, notif : [id]});
            getData()
            if(openedNotification) setOpenedNotificaiton('')
            if(notificationOpen) setNotificationOpen(false)
        }catch(error){
            console.log(error)
        }
        triggerLoader(false)
    }
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
        if(e.target.innerText === 'Latest first' && sort === -1) return null
        if(e.target.innerText === 'Oldest first' && sort === 1) return null
        if(e.target.innerText === 'Latest first')  setSort(-1)
        if(e.target.innerText === 'Oldest first')  setSort(1)
    };
    const handleSelectAll = () => {
        setSelectAll(!selectAll)
        let tempSelected = []
        if(!selectAll){
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                const ids = currentArray.map(item => item.id);
                return acc.concat(ids);
              }, []);
        }
        setSelected(tempSelected)
    }
    const eachNotification = (main,data) => {
        return(
            <div className={`notification_each_notification ${data?.id === openedNotification?.id && 'notification_opened'}`} onClick={(e)=>handleOpenNotification(e,data)}>
                <div className="notification_each_notification_check">
                    <input
                        id = {data?.id}
                        type="checkbox"
                        className="notification_checkbox"
                        checked={selected.includes(data?.id)}
                        onClick={(event) => onCheck(event,data?.id)}
                    />
                </div>
                <div className={`notification_each_notification_right `}>
                    <span className={!data?.read ? 'content notification_each_notification_unread_main' : 'content'}>{data?.header}</span>
                    <div className="notification_each_notification_right_sub">
                        <div className="content notification_each_notification_right_sub_date_container">
                            {(main === 'Today' || main === 'Yesterday') ? (
                                <span className={!data?.read && 'notification_each_notification_unread_sub'}>{moment(data?.dateTime).format('hh:mm A')}</span>
                            ) : (
                                <span className={!data?.read && 'notification_each_notification_unread_sub'}>{moment(data?.dateTime).format('MMM D')}{' '}{moment(data?.dateTime).format('hh:mm A')}</span>
                            )}
                        </div>
                        <div className="notification_each_notification_right_sub_delete"  style={{width :'33.3%'}}>
                            <DeleteOutlineIcon onClick={(e)=>getConfirmation('deleteSingleNotification','Are you sure you want to delete notification ?',e,data?.id)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    // const truncateHtml = (html, maxLength) => {
    //     const div = document.createElement('div');
    //     div.innerHTML = html;
    //     let truncated = div.textContent || div.innerText || "";
    //     if (truncated.length > maxLength) {
    //       truncated = truncated.substring(0, maxLength) + '...';
    //     }
    //     return truncated;
    // };
    useEffect(()=>{
        getData() 
    },[user,type,sort, user])
    useEffect(()=>{
        const timeOut = setTimeout(()=>{
            getData()
        }, 1500)
        return ()=>{
            clearTimeout(timeOut)
        }
    },[search])

    //dropdown start
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [options] = useState(['All', 'Read', 'Unread']);
    const dropdownRef = useRef(null);

    // for dropdown close on clicking outside the focus
    useEffect(() => {
        const handleClickOutside = (event) => {
          event.stopPropagation();
          if (
            dropdownRef.current && 
            !dropdownRef.current.contains(event.target)
          ) {
            setShowDropdown(false);
          }
        };
      
          document.addEventListener('mousedown', handleClickOutside);
      
          return () => {
            document.removeEventListener('mousedown', handleClickOutside);
          };
    }, [dropdownRef]);

    useEffect(() => {
        const handleKeyDown = (event) => {
        if (!showDropdown) return;

        if (event.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) =>
            prevIndex < options.length - 1 ? prevIndex + 1 : 0
            );
        } else if (event.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : options.length - 1
            );
        } else if (event.key === 'Enter' && selectedIndex >= 0) {
            alert(`Selected: ${options[selectedIndex]}`);
            setShowDropdown(false);
        }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
        document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showDropdown, selectedIndex, options]);

    const handleIconClick = () => {
        setShowDropdown((prev) => !prev);
    };

    const handleOptionClick = (index) => {
        setShowDropdown(false);
        let tempSelected = []
        if(index == 0){
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                const ids = currentArray.map(item => item.id);
                return acc.concat(ids);
              }, []);
        }else if(index === 1){
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                currentArray.forEach(item => {
                  if (item.read) {
                    acc.push(item.id);
                  }
                });
                return acc;
              }, []);
        }else{
            tempSelected = Object.values(notifications).reduce((acc, currentArray) => {
                currentArray.forEach(item => {
                  if (!item.read) {
                    acc.push(item.id);
                  }
                });
                return acc;
              }, []);
        }
        setSelected(tempSelected)
        setSelectAll(true)
    };
    //dropdown end

    const sanitizeHTML = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    }
    return(
        <>
            <div className='Spotlet'>
                <Header/>
                <div className='spotlet_main d-flex flex-row'>
                    <LeftSideBar/>
                    <div className="sp_notifications" style={{height : "calc(100vh - 70px )"}}>
                        <div className='row h-100'>
                            <div className="col-lg-4">
                                <div className="notification_main_section">
                                    <div>
                                        <h3 className="etitle main_text">Notifications</h3>
                                    </div>
                                    <div className="notification_section_head">
                                        <div className="notification_event_type_container">
                                            <div onClick={()=>onChangeType('Total')} className={type == 'Total' ? `sub_text notification_event_type_button_active` : `sub_text notification_event_type_button`}>Total : {totalNotificationCount}</div>
                                            <div onClick={()=>onChangeType('Unread')} className={type == 'Unread' ? `sub_text notification_event_type_button_active` : `sub_text notification_event_type_button`}>Unread : {unreadNotificationCount}</div>
                                            <div onClick={()=>onChangeType('Read')} className={type == 'Read' ? `sub_text notification_event_type_button_active` : `sub_text notification_event_type_button`}>Read : {readNotificationCount}</div>
                                        </div>
                                    </div>
                                    <div className="notification_search_section">
                                        <input
                                            type="text"
                                            className="textFiled w-100 mb-2"
                                            value={search}
                                            onChange={handleSearch}
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                            <div className="notification_selection_Section">
                                                <div className="d-flex flex-row justify-content-center align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="sp_check"
                                                        style={{marginRight : '0px',marginLeft : '7px'}}
                                                        checked={selectAll}
                                                        onClick={handleSelectAll}
                                                    />
                                                    <div>
                                                        <div onClick={handleIconClick} style={{ cursor: 'pointer',marginRight : '5px' }} className="d-flex flex-row justify-content-center align-items-center notification_dropdown_container">
                                                            {/* Conditionally render the arrow icon */}
                                                            {showDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </div>
                                                        {showDropdown && (
                                                            <ul className="notification_page_dropdown" ref={dropdownRef}>
                                                                {options.map((option, index) => (
                                                                <li
                                                                    key={index}
                                                                    onClick={() => handleOptionClick(index)}
                                                                    className={`notification_dropdown_option content ${index === selectedIndex ? 'selected' : ''}`}
                                                                >
                                                                    {option}
                                                                </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                </div>
                                                {selected.length > 0 && (
                                                    <>
                                                        <span className="content mr-1 d-flex" onClick={()=>getConfirmation('markSelected','Are you sure you want to mark the selected notifications as read?')}><DoneAllIcon />{' '}<span className='ml-1'> Mark as read </span></span>
                                                        <span className="content" onClick={()=>getConfirmation('deleteSelected','Are you sure you want to delete the selected notifications?')} ><DeleteOutlineIcon/>Delete</span>
                                                    </>
                                                )}
                                            </div>
                                        <div className="notification_event_type_button mx-0">
                                            <span className="content" onClick={handleClick}><SwapVertIcon />{' '}Sort by</span>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    width: '20ch',
                                                },
                                                }}
                                            >
                                                {sortOptions.map((option) => (
                                                <MenuItem key={option} selected={option === 'Letest first'} onClick={handleClose}>
                                                    {option}
                                                </MenuItem>
                                                ))}
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="notification_all_section">
                                        {Object.keys(notifications).map((main) => {
                                            return(
                                                <div>
                                                    <div className="notification_main_date_container">
                                                        <span>{main}</span>
                                                    </div>
                                                    {notifications?.[main]?.map((each) => {
                                                        return eachNotification(main,each)
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            {notificationOpen && (
                                <div className="col-lg-8 d-flex flex-row jutify-content-start align-items-start d-lg-flex d-none" style={{marginTop : '80px'}}>
                                    <div className="notification_open_container">
                                        <div className="d-flex flex-row">
                                            <img src={logo} alt="logo" className="notifications_logo"/>
                                            <div className="w-100 d-flex flex-row justify-content-between">
                                                <div className="d-flex flex-column">
                                                    <span className="notification_each_notification_unread_main sub_text">{openedNotification?.header}</span>
                                                    <span className="content">{moment(openedNotification?.dateTime).format('MMM D')}{' '}{moment(openedNotification?.dateTime).format('hh:mm A')}</span>
                                                </div>
                                                <div className="cursor"><DeleteOutlineIcon onClick={(e)=>getConfirmation('deleteSingleNotification','Are you sure you want to delete notification ?',e,openedNotification?.id)} /></div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="content" dangerouslySetInnerHTML={{ __html: sanitizeHTML(openedNotification?.text) }}></span>
                                        </div>
                                    </div>
                                </div>
                            )} 
                            { (totalNotificationCount && !notificationOpen) && (
                            <div className="d-none d-lg-flex col-lg-8 flex-row jutify-content-center align-items-start" style={{marginTop : '80px'}}>
                                <div className="w-100 d-flex justify-content-center">
                                    <span className="main_text"> No conversations selected </span>
                                </div>
                            </div>
                            ) } 
                        </div>
                    </div>
                    {showNotificationModal && <NotificationPopupModal 
                        show={showNotificationModal}
                        title={'Notification Details'}
                        body={openedNotification}
                        sanitizeHTML= {(data) => sanitizeHTML(data)}
                        onHide={()=> {
                            setShowNotificationModal(false);
                            setNotificationOpen(false);
                            setOpenedNotificaiton('');
                        }}
                        deleteSingleNotification={(e,id)=> {
                            getConfirmation('deleteSingleNotification','Are you sure you want to delete notification ?',e,id);
                            setShowNotificationModal(false);
                            setNotificationOpen(false);
                            setOpenedNotificaiton('');
                        }}
                    />}
                </div>
            </div>
        </>    
    )
}
export default Notifications;