import React, { useState ,useEffect} from 'react';
import { Link } from "react-router-dom";
import { useNavigate,useLocation } from 'react-router-dom';
import { useUser } from '../../Utils/Auth';
import Auth from '../../Modals/Auth';
import './Header.css';

//images start
// import logo from "../../Assets/Images/Header/spotlet_logo.webp"
import logo from "../../Assets/Images/Header/spotlet_logo.png"
import home_no from "../../Assets/Images/Header/home_no.svg"
import listings_no from "../../Assets/Images/Header/listings_no.svg"
import calender_no from "../../Assets/Images/Header/calender_no.svg"
import anylitics_no from "../../Assets/Images/Header/analytics_no.svg"
import support_no from "../../Assets/Images/Header/support_no.svg"
import settings_no from "../../Assets/Images/Header/settings_no.svg"
import home from "../../Assets/Images/Header/home.svg"
import listings from "../../Assets/Images/Header/listings.svg"
import calender from "../../Assets/Images/Header/calendar.svg"
import anylitics from "../../Assets/Images/Header/analytics.svg"
import support from "../../Assets/Images/Header/support.svg"
import settings from "../../Assets/Images/Header/settings.svg"
import menu from "../../Assets/Images/Header/menu.svg"
import recent from "../../Assets/Images/Header/recent.svg"
import recent_no from "../../Assets/Images/Header/recent_no.svg"
//images end

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

//Icons start

//Icons end
const Notifications = React.lazy(()=> import("../Notifications/Notifications"))
const Favorites = React.lazy(()=> import("../Favorites/Favorites"))
const Profile = React.lazy(()=> import("../Profile/Profile"))
const DASDH_ITEMS = [
  { name: 'Home', active: home, noActive: home_no, path: 'home' },
  { name: 'Listings', active: listings, noActive: listings_no, path: 'listings' },
  { name: 'Calendar', active: calender, noActive: calender_no, path: 'calendar' },
  { name: 'Recent Events', active: recent, noActive: recent_no, path: 'recent-events' },
  { name: 'Analytics', active: anylitics, noActive: anylitics_no, path: 'analytics' },
  { name: 'Support', active: support, noActive: support_no, path: 'support' },
  { name: 'Settings', active: settings, noActive: settings_no, path: 'settings' }
];
const Header = () => {
  const {user} = useUser()
  const navigate = useNavigate()
  const location = useLocation()?.pathname;
  const [activeTab,setActiveTab] = useState('')
  const [showAuth,setShowAuth] = useState(false)
  const [drawerOpen,setDrawerOpen] = useState(false)
  const toggleAuth = (tab) => {
    setActiveTab(tab)
    setShowAuth(!showAuth)
  }
  const handleSuccess = () => {
    setShowAuth(!showAuth)
  }
  const handleDashNav = (name) => {
    navigate(`/${name.toLowerCase()}`)
  }
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
}
  const [showHeader, setShowHeader] = useState(true);
  // const [lastScrollY, setLastScrollY] = useState(0);

  // const handleScroll = () => {
  //   const currentScrollY = window.scrollY;
    
  //   if (currentScrollY > lastScrollY && currentScrollY > 100) {
  //     // Scrolling down
  //     setShowHeader(false);
  //   } else {
  //     // Scrolling up
  //     setShowHeader(true);
  //   }
    
  //   setLastScrollY(currentScrollY);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
    
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [lastScrollY]);
  return (
    <header className={`header ${showHeader ? 'visible' : 'hidden'}`}>
      <div className='d-flex flex-row justify-content-center align-items-center'>
        {(location === '/home' || 
          location === '/listings' || 
          location === '/calendar' || 
          location === '/recent-events' ||
          location === '/analytics' || 
          location === '/support' || 
          location === '/settings') && (
            <div className='header_dashbaord_mobile'>
              <img src={menu} className='header_icon_sec_dash_icon cursor' alt='menu' onClick={toggleDrawer}/>
              <SwipeableDrawer
                  anchor='left'
                  open={drawerOpen}
                  onClose={toggleDrawer}
                  onOpen={toggleDrawer}
              >
                  <Box
                      sx={{ width: 300 }}
                      role="presentation"
                      onClick={toggleDrawer}
                      onKeyDown={toggleDrawer}
                      >
                      <List>
                        {DASDH_ITEMS?.map((each)=> {
                          return(
                            <ListItem disablePadding className="sp_header_sidebar_item_container" onClick={()=>handleDashNav(each?.path)}>
                                <ListItemButton>
                                    <ListItemIcon>
                                      <img className='header_icon_sec_dash_icon' src={location === `/${each?.path}` ? each?.active : each?.noActive} alt='icon'/>
                                    </ListItemIcon>
                                    <span className={location === `/${each.path}` ? `content required` : `content text_color_3`}>{each?.name}</span>
                                </ListItemButton>
                            </ListItem>
                          )
                        })}
                      </List>
                      </Box>
              </SwipeableDrawer>
            </div>
        )}
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img className='header_logo' src={logo} alt="Spotlet application logo" />
        </Link>
      </div>
      {(location === '/home' || 
        location === '/listings' || 
        location === '/calendar' || 
        location === '/recent-events' ||
        location === '/analytics' || 
        location === '/support' || 
        location === '/settings') && (
        <div className='header_dashbaord'>
          {DASDH_ITEMS?.map((each)=> {
            return(
              <div className='header_icon_sec' onClick={()=>handleDashNav(each?.path)}>
                <img className='header_icon_sec_dash_icon' src={location === `/${each.path}` ? each?.active : each?.noActive} alt='icon'/>
                <span className={location === `/${each.path}` ? `content required` : `content text_color_3`}>{each?.name}</span>
              </div>
            )
          })}
        </div>
      ) }
      <div className='header_icon_section'>
        {user ? (
          <>
            <Notifications onClick={()=> navigate('/notifications')}/>
            <Favorites/>

          </>
        ) : (
          <>
            {/* <span className='content cursor' onClick={()=> navigate("/auth",{ state: {tab : 'register'} })}>Register</span> */}
            {/* <span className='content cursor' onClick={()=> navigate("/auth",{ state: {tab : 'login'} })}>Log In</span> */}
            <span className='content cursor' onClick={()=>toggleAuth('register')}>Register</span>
            <span className='content cursor' onClick={()=>toggleAuth('login')}>Log In</span>
          </>
        )}
        <Profile/>
      </div>
      {showAuth && <Auth show={showAuth} onHide={toggleAuth} onSuccess={handleSuccess} activeTab={activeTab}/>}
    </header>
  );
};

export default Header;