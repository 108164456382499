import React, { Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import Fallback from "./Fallbacks/Fallback";
const LazyLoader = ({Component,...props}) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1, // Trigger when 10% of the component is visible
    });
  
    return (
        <div ref={ref} id={props?.id}>
        {inView ? (
          <Suspense fallback={<Fallback/>}>
            <Component  {...props}/>
          </Suspense>
        ) : (
          <Fallback/>
        )}
      </div>
    );
};
export default LazyLoader;