import "./LeftSideBar.css"
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { useNavigate,useLocation} from "react-router-dom";
import { useAuth } from "../../Utils/Auth";
import { useUser } from "../../Utils/Auth";
const LeftSideBar = () => {
    const navigate = useNavigate()
    const location = useLocation()?.pathname;
    const {logout} = useAuth()
    const {user} = useUser()
    const handleLogout = () => {
        logout()
        navigate('/')
    }
    return(
        <div className="sp_left_sidebar">
            <ul className="p-0 m-0">
                {user?.listings || user?.incompleteListing ? (
                    <li className={location == '/dashboard' ? `sp_left_sidebar_item_active` : `sp_left_sidebar_item`} onClick={()=>navigate('/home')}>
                        <AddHomeOutlinedIcon className="sp_left_sidebar_icon"/>
                        <span className="sub_text">Host Dashboard</span>
                    </li>
                ) : (
                    <li className={location == '/host' ? `sp_left_sidebar_item_active` : `sp_left_sidebar_item`} onClick={()=>navigate('/host')}>
                        <AddHomeOutlinedIcon className="sp_left_sidebar_icon"/>
                        <span className="sub_text">Become a Host</span>
                    </li>
                )}
                <li className={location == '/edit-profile' ? `sp_left_sidebar_item_active` : `sp_left_sidebar_item`} onClick={()=>navigate('/edit-profile')}>
                    <ManageAccountsOutlinedIcon className="sp_left_sidebar_icon"/>
                    <span className="sub_text">Edit Profile</span>
                </li>
                <li className={location == '/notifications' ? `sp_left_sidebar_item_active` : `sp_left_sidebar_item`} onClick={()=>navigate('/notifications')}>
                    <NotificationsActiveOutlinedIcon className="sp_left_sidebar_icon"/>
                    <span className="sub_text">Notifications</span>
                </li>
                <li className={location == '/favourites' ? `sp_left_sidebar_item_active` : `sp_left_sidebar_item`} onClick={()=>navigate('/favourites')}>
                    <FavoriteBorderOutlinedIcon className="sp_left_sidebar_icon"/>
                    <span className="sub_text">Favourites</span>
                </li>
                <li className={location == '/enquiries' ? `sp_left_sidebar_item_active` : `sp_left_sidebar_item`} onClick={()=>navigate('/enquiries')}>
                    <ChatOutlinedIcon className="sp_left_sidebar_icon"/>
                    <span className="sub_text">My Enquiries</span>
                </li>
                <li className="sp_left_sidebar_item" onClick={handleLogout}>
                    <LogoutOutlinedIcon className="sp_left_sidebar_icon"/>
                    <span className="sub_text">Log Out</span>
                </li>
            </ul>
        </div>
    )
}
export default LeftSideBar;