import ModalWrapper from './ModalWrapper/ModalWrapper';

export const DetailsPopup = ({
    ...props
}) => {
    return (
        <ModalWrapper
            heading={props?.title}
            props={props}
        >
            <div 
                style={{  overflowY: "auto" }}
            >
                {props?.data}
            </div>
        </ModalWrapper>
    );
}
