import React, { useState, useEffect, } from 'react';
import ToolTip from '../Utils/ToolTip';
import "./Input.css"
const PasswordInput = ({
  id,
  label,
  name,
  value,
  onChange,
  placeholder,
  validate,
  errorMessage,
  required,
  title,
}) => {
  const [error, setError] = useState('');
  const [type, setType] = useState('password');
  const handlePassType = () => {
    if(type === 'password'){
      setType('text')
    }else{
      setType('password')
    }
  }
  const handleBlur = () => {
    if (validate) {
      const validationError = validate(value);
      setError(validationError);
    }
  };

  useEffect(() => {
    if(required) setError(errorMessage);
  }, [errorMessage,required]);

  return (
    <div className="sp_text_container">
      <label htmlFor={id} className='content font-weight-normal text_color_1'>{label || 'Label'}
        {(required && label) && <span className='required'>{' '}*</span>}
        {(required && title) && (<ToolTip content={title}/>)}
      </label>
      <div className='sp_input_container'>
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          placeholder={placeholder || "Enter Value"}
          className={`sp_input ${error && 'error'}`}
          title={title}
        />
        <span className="sp_input_icon content brand_color cursor" onClick={handlePassType}>{type === 'password' ? 'Show' : 'Hide'}</span>
      </div>
      <span className="content error_text">{error}</span>
    </div>
  );
};

export default PasswordInput;
