import React, { useState, useEffect, useRef} from 'react';
import ToolTip from '../Utils/ToolTip';
import "./Input.css"
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function SearchableSelect({
  id,
  label,
  name,
  options,
  value,
  onChange,
  placeholder,
  validate,
  errorMessage,
  required,
  title,
  style,
  labelStyle,
  inputStyle,
  disabled,
  hideClearButton,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [error, setError] = useState('');
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const inputRef = useRef(null);  // Ref for the input element

  // for key down functionality
  const [focusedIndex, setFocusedIndex] = useState(0);

  const handleSearchChange = (e) => {
    setSelectedOption('');
    setSearchTerm(e.target.value);
    const filteredData = options?.filter((option) =>
      option?.label?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    setFilteredOptions(filteredData);
    setShowOptions(true);
    setFocusedIndex(0);  // Reset focus to the first option
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex((prevIndex) => {
        const newIndex = prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0;
        document.getElementById(`option-${newIndex}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
        return newIndex;
      });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex((prevIndex) => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1;
        document.getElementById(`option-${newIndex}`)?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
        return newIndex;
      });
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (filteredOptions.length > 0) {
        handleOptionClick(filteredOptions[focusedIndex]);
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setShowOptions(false);
    }
  };

  const handleOptionClick = (option) => {
    setSearchTerm('');
    onChange(name, option?.value);
    setShowOptions(false);
    setSelectedOption(option.label);
  };

  const handleBlur = () => {
    if (validate) {
      const validationError = validate(value);
      setError(validationError);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const onArrowClick = () => {
    setShowOptions(!showOptions);
    inputRef.current?.focus();  // Focus the input when the arrow is clicked
  };

  const onClearClick = () => {
    onChange(name, '');
  };

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!value) {
      setSelectedOption('');
    }
    for (let each of options) {
      if (each.value.toLowerCase() === value.toLowerCase()) {
        setSelectedOption(each.label);
      }
    }
    setFilteredOptions(options);
  }, [value, options]);

  return (
    <div className="sp_select_container" style={style}>
      <label htmlFor={id} className='content text_color_1' style={labelStyle}>
        {label}
        {(required && label) && <span className='required'>{' '}*</span>}
        {(required && title) && (<ToolTip content={title} />)}
      </label>
      <div className='sp_select_input_container'>
        <input
          type="text"
          id={id}
          value={searchTerm || selectedOption}
          onFocus={() => setShowOptions(true)}
          onChange={handleSearchChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}  // <== Handle key events in input
          placeholder={placeholder}
          className={`sp_input ${error && 'error'}`}
          title={title}
          disabled={disabled}
          style={{
            ...inputStyle,
            pointerEvents: disabled ? 'none' : 'auto',
          }}
          ref={inputRef}  // <== Reference for the input element
        />
        <div className='sp_select_icon_container'>
          {(selectedOption && !hideClearButton) && (
            <span className='sp_select_icon_clear cursor' onClick={onClearClick}>
              <ClearIcon fontSize='small' color='disabled' />
            </span>
          )}
          <span className='sp_select_icon_arrow cursor' ref={iconRef} onClick={onArrowClick}>
            <KeyboardArrowDownIcon color='disabled' />
          </span>
        </div>
      </div>
      {showOptions && (
        <ul className="sp_options_list" ref={dropdownRef}>
          {filteredOptions?.map((option, index) => (
            <li
              key={index}
              id={`option-${index}`}
              onClick={() => handleOptionClick(option)}
              className={`sp_option_item ${index === focusedIndex ? 'highlighted' : ''}`}
              style={style}
            >
              {option?.label}
            </li>
          ))}
          {!filteredOptions?.length && <li className="sp_option_item">No Options</li>}
        </ul>
      )}
      <span className="content error_text">{error}</span>
    </div>
  );
}

export default SearchableSelect;
