import React, { useState, useEffect } from 'react';
import ToolTip from '../Utils/ToolTip';
import "./Input.css"
const TextInput = ({
  id,
  label,
  name,
  value,
  onChange,
  placeholder,
  validate,
  errorMessage,
  required,
  title,
  disabled,
}) => {
  const [error, setError] = useState('');

  const handleBlur = () => {
    if (validate) {
      const validationError = validate(value);
      setError(validationError);
    }
  };

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);


  return (
    <div className="sp_text_container">
            <label htmlFor={id} className='content font-weight-normal text_color_1'>{label}
              {(required && label) && <span className='required'>{' '}*</span>}
              {(required && title) && (<ToolTip content={title}/>)}
              </label>
            <input
              type='text'
              id={id}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={handleBlur}
              placeholder={placeholder}
              className={`sp_input ${error && 'error'}`}
              title={title}
              disabled={disabled || false}
            />
            <span className="content error_text">{error}</span>
          </div>
  );
};

export default TextInput;
