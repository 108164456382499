import React from 'react';
import { Navigate,useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const validateTokenExpiration = (token) => {
  if (!token) return false;
  try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && currentTime > decodedToken.exp) {
          return false;
      }
      return true;
  } catch (error) {
      console.error("Invalid token", error);
      return false;
  }
};

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem('auth')
  const isTokenValid = validateTokenExpiration(token);
  if(!isTokenValid) return <Navigate to="/auth" replace state={{ from: location }} />
  return children;
};

export default ProtectedRoute;
