import React from "react";
import Header from "../../Components/Header/Header";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './Favourites.css';
import SearchInput from '../../Inputs/SearchInput'
import {getNewFavouritesList} from '../../Apis/Api';
import { useAuth } from "../../Utils/Auth";
import { useUser } from "../../Utils/Auth";
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import PropertyTile from "../../Components/PropertyTile/PropertyTile";
import { Pagination } from '@mui/material';
const Favourites = ()=>{
  const navigate = useNavigate()
  const {triggerLoader, notify } = useAuth();
  const { user,getUser } = useUser();
  const [searchInput, setSearchInput] = useState('')
  const [favouritesList, setFavouriesList] = useState([]);
  const [sort, setSort] = useState('')
  const [count, setCount] = useState(0);
  const [showCount, setShowCount] = useState([0, 15]);
  const [compareList, setCompareList] = useState([]);
  const getFavourites = async () => {
    triggerLoader(true)
    try {
      const userResp = await getUser()
      const resp = await getNewFavouritesList(sort,searchInput);
      setCount(Math.ceil(resp.data.length / 15))
      setFavouriesList(resp.data);
    } catch (error) {
      console.log(error)
    }
    triggerLoader(false)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
      getFavourites();
  }, []);
  const handleSearch = async (e) => {
    try {
      setSearchInput(e.target.value)
      const resp = await getNewFavouritesList(sort, e.target.value);
      setCount(Math.ceil(resp.data.length / 28))
      setFavouriesList(resp.data);
    } catch (error) {
      console.log(error);
    }
  }
  const handleCompareChange = (locationId) => {
    if (compareList.includes(locationId)) {
      setCompareList((prev) => prev.filter((element) => element !== locationId));
    }else{
      if (compareList.length === 3) {
        notify('You can choose only 3 properties','error')
        return null
      }
      setCompareList((prev) => [...prev, locationId])
    }
  }
  const onCompare = () => {
    if (compareList.length < 2) {
      notify('Please select atleast 2 properties','error')
      return null
    }
    navigate('/compare',{state : {compareList}})
  }
  const handleChangePage = async (event, value) => {
    setShowCount([(value * 15) - 15, value * 15])
    window.scrollTo(0, 0);
  }

    return(
        <div className="Spotlet">
            <Header/>
            <div className="spotlet_main d-flex">
            <LeftSideBar/>
            <div className="favourite">
                <div className="favorite_header">
                    <span className="favorite_main_text main_text heading_color_1">Your Personal Shortlist</span>
                    <div className="favourite_search_container">
                      <SearchInput
                        id='search_input'
                        value={searchInput || ""}
                        onChange={handleSearch}
                        placeholder='Search favourite by id...'
                        />
                    </div>
                    {/* <div className="fav_sort_btn_container">
                      <button className="btn btn-primary sp_button w-100">Sort By</button>
                    </div> */}
                    <div className="fav_compare_btn_container">
                      <button className="btn btn-primary sp_button w-100" onClick={onCompare}>Compare properties</button>
                    </div>
                </div>
                <div className="favorite_header mb-2">
                  <span className="content text_color_1">Curate your dream locations with our 'Favorites' feature. Easily compare and decide on the perfect venue for your film shoot, corporate event, or special occasion. Your ideal spot is just a click away!</span>
                </div>
                {favouritesList.length > 0 && (
                    <div className="favorite_contentBox">
                        {favouritesList
                            .slice(showCount[0], showCount[1])
                            ?.map((property, i) => (
                                <div className="favourite_property_container" key={i}>
                                    <PropertyTile
                                        data = {property}
                                        refresh={getFavourites}
                                    />
                                    <div className="favourite_compare_input cursor mt-1">
                                        <input
                                            className="sp_check"
                                            type="checkbox"
                                            checked={compareList.includes(property?.location_id)}
                                            id={`compare${i}`}
                                            onClick={() =>
                                                handleCompareChange(property?.location_id)
                                            }
                                        />
                                        <label
                                            className="content m-0"
                                            htmlFor={`compare${i}`}
                                        >
                                            Compare properties
                                        </label>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
                {!favouritesList && (
                    <div className='d-flex justify-content-center align-items-center mt-5'>
                    <span className="sub_text">No Favourites Found</span>
                    </div>
                )}
                {count > 1 && (
                    <div className="d-flex flex-row justify-content-center m-3">
                    <Pagination
                        count={count}
                        variant="outlined"
                        onChange={handleChangePage}
                    />
                    </div>
                )}
            </div>
            </div>
        </div>
    )
}
export default Favourites;