import Autheniticator from '../Components/Autheniticator/Autheniticator';
import Modal from 'react-bootstrap/Modal';
import "../Modals/ModalWrapper/ModalWrapper.css"
import CloseIcon from '@mui/icons-material/Close';
const Auth = (props) => {
    return(
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='sp_modal_head justify-content-end'>
                    {/* <span className='main_text heading_color_1'>{heading}</span> */}
                    <div className='cursor' onClick={props.onHide}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className='sp_modal_body'>
                    <div className='modal_authenticator_main'>
                        <Autheniticator activeTab={props?.activeTab} onSuccess={props.onSuccess}/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default Auth;