import React from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import './Footer.css';

//images start
import logoName from '../../Assets/Images/Footer/spotlet_name.svg'
//images end
//icons start
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
//icons end

import {useAuth} from '../../Utils/Auth'
import EmailInput from '../../Inputs/EmailInput';
import { sendNewsLetterMail } from '../../Apis/Api';

const Footer = () => {
  console.log("FOOTER")
  const {notify} = useAuth()
  const [formData,setFormData] = useState({})
  const [errors, setErrors] = useState({});
  const onChange = (e) => {
    const {name,value} = e.target
    setFormData({
        ...formData,
        [name] : value,
    })
    if(value){
        setErrors({ ...errors, [name]: '' });
    }else{
        setErrors({...errors,[name] : `This field is required`})
    }
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return regex.test(email) ? '' : 'Invalid email format';
  };
  const validateForm = () => {
    const newErrors = {
        email: formData.email ? validateEmail(formData.email) : 'Email is required',
    };
    setErrors(newErrors);

    // Return false if there are errors
    for(let key of Object.keys(newErrors)){
        if(newErrors[key] !== ''){
            return false
        }
    }
    return true
  };
  const onSubmit = async (e) => {
      e.preventDefault()
      if (validateForm()) {
        console.log('Form submitted successfully:', formData);
        try{
          const res = await sendNewsLetterMail(formData.email);
          notify(res?.data?.message,'success')
          setFormData({})
        } catch(error) {
          console.log(error)
          notify(error?.response?.data?.message,'error')
        }
      } else {
        console.log('Form validation failed');
      }
  };
  return (
    <footer className="footer footer_content">
        <div className='spotlet_footer_section'>
          <div className='container-fluid m-0 p-0'>
            <div className='row'>
              <div className='col-lg-3 d-flex flex-column justify-content-center align-items-start'>
                <div className='mb-4'>
                  <img src={logoName} alt='spotLet solutions'/>
                </div>
                <p className="content text-left">
                  SpotLet is a platform where hosts, guests, clients and
                  customers come together to find their dream spaces! It’s a
                  collaboration of ideas, places and people to create beautiful
                  moments and memories.
                </p>
              </div>
              <div className='col-lg-9 m-0 p-0'>
                <div className='row'>
                  <div className='col-lg-3 col-md-6'>
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                      <h3 className='main_text text-light'>COMPANY</h3>
                      <ul className='d-flex flex-column justify-content-start align-items-start'>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={'/about-us'} target="_blank"> About Us</Link>
                        </li>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={'/careers'} target="_blank"> Careers</Link>
                        </li>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={{pathname: process.env.REACT_APP_BLOGS_LINK }} target="_blank"> Blogs</Link>
                          {/* <a className='cursor' href={process.env.REACT_APP_BLOGS_LINK} target="_blank" rel="noreferrer noopener"><i className="bi bi-chevron-right footer_content content"></i> Blogs</a> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                      <h3 className='main_text text-light'>HOST</h3>
                      <ul className='d-flex flex-column justify-content-start align-items-start'>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={'/host'} target="_blank"> Become a Host</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                      <h3 className='main_text text-light'>SUPPORT</h3>
                      <ul className='d-flex flex-column justify-content-start align-items-start'>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={'/contact-us'} target="_blank"> Contact Us</Link>
                        </li>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={'/cancellation-and-refund'} target="_blank"> Cancellation</Link>
                        </li>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={'/privacy-policy'} target="_blank"> Privacy Policy</Link>
                        </li>
                        <li>
                          <Link className="bi bi-chevron-right footer_content content" to={'/terms-and-conditions'} target="_blank"> Terms and Conditions</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='d-flex flex-column justify-content-start align-items-start'>
                      <h3 className='main_text text-light'>NEWSLETTER</h3>
                      <form className='d-flex flex-column justify-content-start align-items-start' onSubmit={onSubmit}>
                        <span className='content text-left'>Subscribe to our Newsletter</span>
                        <span className='content text-left'>we won't spam.</span>
                        <div className='d-flex flex-column justify-content-start align-items-start'>
                          <EmailInput
                            id='footer_email'
                            // label='Email'
                            name='email'
                            value={formData?.email || ""}
                            onChange={onChange}
                            placeholder='example@example.com'
                            validate={(value) => (value ? '' : 'Email is required')}
                            errorMessage={errors?.email}
                            required={true}
                            title="Please enter a valid email address."
                          />
                          <button type='submit' className='btn btn-primary sp_button w-100'>Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div className='container-fluid m-0 p-0'>
          <div className='row'>
            <div className='col-lg-6 d-flex flex-row justify-content-start align-items-center'>
              <span className='content'>@2023 SpotLet Solutions Private Limited. All rights Reserved.</span>
            </div>
            <div className="col-lg-6 d-flex flex-row justify-content-end align-items-center">
              <div className='footer_icon_section'>
                <a href="https://www.facebook.com/SpotLetIN" target="_blank" rel="noreferrer noopener" aria-label="facebook">
                  <FacebookIcon color='light'/>
                </a>
                <a href="https://twitter.com/SpotLetIN" target="_blank" rel="noreferrer noopener" aria-label="twitter">
                  <XIcon color='light'/>
                </a>
                <a href="https://www.linkedin.com/company/spotletin/" target="_blank" rel="noreferrer noopener" aria-label="linkedin">
                  <LinkedInIcon color='light'/>
                </a>
                <a href="https://www.instagram.com/spotletin/" target="_blank" rel="noreferrer noopener" aria-label="instagram">
                  <InstagramIcon color='light'/>
                </a>
                <a href="https://in.pinterest.com/SpotLetIN/" target="_blank" rel="noreferrer noopener" aria-label="pintrest">
                  <PinterestIcon color='light'/>
                </a>
                <a href="https://www.youtube.com/@spotlet" target="_blank" rel="noreferrer noopener" aria-label="youtube">
                  <YouTubeIcon color='light'/>
                </a>
              </div>
            </div>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
