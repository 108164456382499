import ModalWrapper from "./ModalWrapper/ModalWrapper"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import logo from "../Assets/Images/Notifications/Favicon.ico"


const NotificationPopupModal = (props) => {
    const {title, body, deleteSingleNotification, sanitizeHTML} = props;
    return(
        <ModalWrapper heading={title} props={props}>
            <div className="notification_open_container">
                <div className="d-flex flex-row">
                    <img src={logo} alt="logo" className="notifications_logo"/>
                    <div className="w-100 d-flex flex-row justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="notification_each_notification_unread_main sub_text">{body?.header}</span>
                            <span className="content">{body?.date}</span>
                        </div>
                        <div className="cursor" onClick={(e)=>deleteSingleNotification(e,body?.id)}><DeleteOutlineIcon/></div>
                    </div>
                </div>
                <div>
                    <span className="content" dangerouslySetInnerHTML={{ __html: sanitizeHTML(body?.text) }}></span>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default NotificationPopupModal