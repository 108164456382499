import React, {useEffect, useState} from "react";
import './Compare.css'
import { useLocation  } from 'react-router-dom';
const Compare = () => {
    const location = useLocation();
    const { state } = location;
    const [data,setData] = useState([])
    const getData = async () => {
        try{
            console.log(state?.compareList)
            const resp = {}
            setData([])
        }catch(error){
            console.log(error)
        }
    }
    useEffect(()=> {
        getData()
    },[])
    return(
        <div>
            <span>Compare properties</span>
        </div>
    )
}
export default Compare;