import React, { createContext, useContext, useState, useMemo, useCallback, useReducer, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import FullPageLoader from './FullPageLoader';
import 'react-toastify/dist/ReactToastify.css';
import { getUserData } from '../Apis/Api';
import HelmetComponent from './Helmet';

// Contexts
const AuthContext = createContext();
const UserContext = createContext();

// Custom hooks
const useAuth = () => useContext(AuthContext);
const useUser = () => useContext(UserContext);

// Reducer for managing user state
const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'CLEAR_USER':
      return null;
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [user, dispatchUser] = useReducer(userReducer, null);
  const toastIdRef = useRef(null);

  // Notification system
  const notify = useCallback((message, type) => {
    toast.dismiss();
    switch (type) {
      case 'info':
        toast.info(message);
        break;
      case 'success':
        toast.success(message);
        break;
      case 'warning':
        toast.warn(message);
        break;
      case 'error':
        toast.error(message);
        break;
      default:
        toast.info(message);
        break;
    }
  }, []);

  // Confirm deletion logic
  const confirm = useCallback((text, buttonName, onConfirm) => {
    toastIdRef.current = toast(
      ({ closeToast }) => (
        <div>
          <p>{text}</p>
          <div className="d-flex flex-row justify-content-end mt-2">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                onConfirm();
                closeToast();
              }}
            >
              {buttonName}
            </button>
          </div>
        </div>
      ),
      { autoClose: false }
    );
  }, []);

  const closeConfirmToast = useCallback(() => {
    if (toastIdRef.current !== null) {
      toast.dismiss(toastIdRef.current);
    }
  }, []);

  // Trigger loader
  const triggerLoader = useCallback((value) => {
    setShowLoader(value);
  }, []);

  // Fetch user data
  const getUser = useCallback(async (token) => {
    try {
      if (token) {
        localStorage.setItem('auth', token);
      }
      let auth = localStorage.getItem('auth');
      const resp = await getUserData(auth);
      dispatchUser({ type: 'SET_USER', payload: resp?.data });
      return resp?.data;
    } catch (error) {
      console.error(error);
      notify(error?.response?.data?.message, "error")
      logout();
    }
  }, []);

  // Logout logic
  const logout = useCallback(() => {
    localStorage.removeItem('auth');
    dispatchUser({ type: 'CLEAR_USER' });
    window.location.reload();
  }, []);

  // Memoized context values to avoid re-renders
  const userContextValue = useMemo(() => ({ user, getUser, dispatchUser }), [user, getUser]);
  const authActions = useMemo(() => ({ logout, notify, triggerLoader, confirm, closeConfirmToast }), [logout, notify, triggerLoader, confirm, closeConfirmToast]);

  return (
    <UserContext.Provider value={userContextValue}>
      <AuthContext.Provider value={authActions}>
        {children}
        <HelmetComponent/>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {showLoader && <FullPageLoader />}
      </AuthContext.Provider>
    </UserContext.Provider>
  );
};

export { AuthProvider, useAuth, useUser };
