import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useEffect, useState } from "react";
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import dayjs from "dayjs";
import Header from '../../Components/Header/Header';
import { useUser } from '../../Utils/Auth';
import { Pagination } from '@mui/material';
import {getUserLeads, getEnquiryData} from '../../Apis/Api';
import {DetailsPopup} from '../../Modals/DetailsPopup';
import moment from 'moment';

import "./Enquiries.css";

const Enquiries = () => {
    const {user} = useUser();
    const [init, setInit] = useState(false);
    const COLUMNS = ["Enquiry Type", 'Enquiry Id', 'Location Id', 'Status', 'Date', 'Action']
    const [rows,setRows] = useState([])
    const [data, setData] = useState([]);
    const [enquiryData,setEnquiryData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [showDetailsPopup, setShowDetailsPopup] = useState(false);

    const PAGE_LIMIT = 20;
    const handleTableData = (data) => {
        Promise.all(data?.map((item)=>{
          return {
            enquiryType : item?.type,
            enquiryId : item?.enquiryId,
            id : item?._id,
            locationId : item?.data?.locationId || item?.data?.property_id,
            status : item?.status,
            date : dayjs(item?.createdAt).format("DD/MM/YYYY"),
            action : 'details',
          }
        })).then((values)=> setRows(values))
      }
    const getEData = async(id) => {
        try{
            const res = await getEnquiryData({ id, read : false});
            setEnquiryData(res?.data?.enquiryDetails);
            setShowDetailsPopup(true);
        } catch (error) {
            console.log(error);
        }
    } 
    const getDetails = (id) => {
        return(
            <button  onClick={() => getEData(id) }
                className="btn btn-outline-danger"
                type='button'
                >
                Details
              </button>
        )
    }
    const actions = (id) => {
        return getDetails(id);
    }
    const getData = async() => {
        if (user){
            try {
                const res = await getUserLeads({ userId: user?._id,type : null, searchTerm, page, limit : PAGE_LIMIT});
                setPageCount(Math.ceil(res?.data?.count / PAGE_LIMIT));
                // setPage(1);
                setData(res?.data?.leads);
                handleTableData(res?.data?.leads);
            } catch (error) {
                console.log(error);
            }
        }
        setInit(true)
    }
    
      useEffect(() => {
            getData();
    }, [user, page,searchTerm]);
    // for pagination 
    const handleChange = async (event, value) => {
        try {
            // const res = await getUserLeads({ userId: user?._id, type : null, searchTerm, page: value, limit : PAGE_LIMIT});
            setPage(value);
            // setPageCount(Math.ceil(res?.data?.count / PAGE_LIMIT)) ;
            // setData(res?.data?.leads);
            // handleTableData(res?.data?.leads);
        } catch (error) {
            console.log(error);
            alert(error?.response?.data?.message);
        }
    };

    const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault(); 
    }
    };
    const FindMySpotDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Full Name :</span> <span className="content">{each?.data?.fullName}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Email :</span> <span className="content">{each?.data?.email}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Type of Event :</span> <span className="content">{each?.data?.eventType}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Preferred Location Type :</span> <span className="content">{each?.data?.locationType}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Date :</span> <span className="content">{moment(each?.eventDate).format('DD-MMM-YYYY')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Estimated Duration :</span> <span className="content">{each?.data?.duration}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Specific Location Features/Requirements :</span> <span className="content">{each?.data?.requirements}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Budget Range :</span> <span className="content">{each?.data?.budget}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Any other Comments/Requests :</span> <span className="content">{each?.data?.requests}</span>
                </div>
            </div>
        )
    } 
    const claimPropertyDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Property Id :</span> <span className="content">{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>First Name :</span> <span className="content">{each?.data?.firstName}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Last Name :</span> <span className="content">{each?.data?.lastName}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Contact No :</span> <span className="content">{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Email :</span> <span className="content">{each?.data?.email}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Relationship with property  :</span> <span className="content">{each?.data?.relationship}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Additional Info :</span> <span className="content">{each?.data?.additionalInfo}</span>
                </div>
            </div>
        )
    } 
    const contactHostDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Property Id :</span> <span className="content">{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                {/* <div className='mt-3'>
						<span  className='content'>Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Email :</span> <span>{each?.data?.email}</span>
                </div> */}
            </div>
        )
    } 
    const subscriptionDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Name :</span> <span className="content">{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Contact No :</span> <span className="content">{each?.data?.phoneNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Email :</span> <span className="content">{each?.data?.email}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Subscription plan :</span> <span className="content">{each?.data?.plan}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Message :</span> <span className="content">{each?.data?.message || "N/A"}</span>
                </div>
            </div>
        )
    } 
    const eventPlanningtDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Full Name :</span> <span className="content">{each?.data?.fullName}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Email :</span> <span className="content">{each?.data?.email}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Contact No :</span> <span className="content">{each?.data?.number}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Name of the Event :</span> <span className="content">{each?.data?.eventType}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Preferred Event Date :</span> <span className="content">{moment(each?.eventDate).format('DD-MMM-YYYY ')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Number of Guests Expected 
                        :</span> <span className="content">{each?.data?.guests}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Area/City 
                        :</span> <span className="content">{each?.data?.eventLocation}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Services Required 
                        :</span> <span className="content">{each?.data?.services}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Specific Themes/Preferences/Requirements
                        :</span> <span className="content">{each?.data?.requirements}</span>
                </div>
            </div>
        )
    } 
    const reportIncorrectInfoDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Property Id :</span> <span className="content">{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId || "N/A"}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                {/* <div className='mt-3'>
						<span  className='content'>Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Email :</span> <span>{each?.data?.email}</span>
                </div> */}
                <div className='mt-3'>
                        <span  className='content heading_color_1'>Report Details :</span>
						<ul>
                            {
                                each?.data?.reportDetails?.map((eachReport) => {
                                    return <li className="content"> {eachReport}  </li>
                                } )  
                            }
                        </ul>
                </div>
                <div className='mt-3 d-flex flex-row'>
						<span  className='content heading_color_1 text-nowrap'>Additional Info :</span> 
                        <span> className="content"{each?.data?.additionalInfo || "N/A"}</span>
                </div>
            </div>
        )
    } 
    
    const getInTouchDetails = (each) => {
        console.log("each", each);
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Property Id :</span> <span className="content">{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId || "N/A"}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Organisation Name :</span> <span className="content">{each?.data?.organization}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Name :</span> <span className="content">{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>User Contact No :</span> <span className="content">{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>User Email :</span> <span className="content">{each?.data?.email}</span>
                </div>
                <div className='mt-3 d-flex flex-row'>
						<span  className='content heading_color_1 me-2 text-nowrap'>Message :</span>  
                        <span className='content'>{each?.data?.message ?  each?.data?.message : "N/A" }</span>
                </div>
            </div>
        )
    } 
    const hostGetInTouchDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId || "N/A"}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Property Name :</span> <span className="content">{each?.data?.property}</span>
                </div>
                {/* <div className='mt-3'>
						<span  className='content'>Enquiry Date & Time :</span> <span>{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div> */}
                {/* <div className='mt-3'>
						<span  className='content'>Organisation Name :</span> <span>{each?.data?.organization}</span>
                </div> */}
                <div className='mt-3'>
						<span  className='content heading_color_1'>Name :</span> <span className="content">{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>User Contact No :</span> <span className="content">{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>User Email :</span> <span className="content">{each?.data?.email}</span>
                </div>
                <div className='mt-3 d-flex flex-row'>
						<span  className='content heading_color_1 me-2 text-nowrap'>Message :</span>  
                        <span className='content'>{each?.data?.message || "N/A"}</span>
                </div>
            </div>
        )
    } 

    const getScheduleVisitDetails = (each) => {
        return(
            <div className='mb-2 mt-2'>
                <div className=''>
						<span  className='content heading_color_1'>Property Id :</span> <span className="content">{each?.data?.locationId}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId || "N/A"}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Enquiry Date & Time :</span> <span className="content">{moment(each?.createdAt).format('DD-MMM-YYYY hh:mm a')}</span>
                </div>
                <div className='mt-3'>
						<span  className='content heading_color_1'>Visit Schedule Date :</span> <span className="content">{each?.data?.date}</span>
                </div>
                {/* <div className='mt-3'>
						<span  className='content'>Name :</span> <span>{each?.data?.name}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Contact No :</span> <span>{each?.data?.mobileNumber}</span>
                </div>
                <div className='mt-3'>
						<span  className='content'>Email :</span> <span>{each?.data?.email}</span>
                </div> */}
                <div className='mt-3 d-flex flex-row'>
						<span  className='content heading_color_1 me-2 text-nowrap'>Message :</span>  
                        <span className='content '>{each?.data?.additionalInfo || "N/A"}</span>
                </div>
            </div>
        )
    } 

    const bookingDetails = (each) => {
        let reqServices 
        if (each?.data?.services){
            const filteredServices = each?.data?.services?.filter((each ) => each !== "")
            reqServices = filteredServices.length > 0 ? each?.data?.services.join(", ") : "N/A";
        }
        else {
            reqServices = "N/A"
        }
        return(
            <div className="d-flex flex-column">
                <div className='card mb-xl-10' >
                    <div className='card-header cursor-pointer'>
                        <div className='mb-3'>
                        {/* begin::Text */}
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Location Id :</span> <span className="content">{each?.data?.locationId}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Enquiry Id :</span> <span className="content">{each?.enquiryId}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Booking Id :</span> <span className="content">{each?._id}</span>
                            </div>
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Type of Event :</span> <span className="content">{each?.data?.event}</span>
                            </div>
                            {/* { 
                            each?.data?.event !== "stay"  && (
                            <div className=' mt-3'>
                                <span  className='fw-bold'>Sub Event :</span> <span>{each?.data?.subEvent}</span>
                            </div>
                            )} */}
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Adults Number:</span> <span className="content">{each?.data?.adults}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Children Number :</span> <span className="content">{each?.data?.children}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Infants Number :</span> <span className="content">{each?.data?.infants}</span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Check In :</span> <span className="content">{moment(each?.data?.checkIn).format('DD/MM/YYYY')} </span>
                            </div>
                            }
                            { 
                            each?.data?.event === "stay"  && 
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>Check Out :</span> <span className="content">{moment(each?.data?.checkOut).format('DD/MM/YYYY')} </span>
                            </div>
                            }
                            { 
                            each?.data?.event !== "stay"  && 
                            <div className='mt-3'>
                                <span  className='content heading_color_1'>No. of Guests  :</span> <span className="content">{each?.data?.guests}</span>
                            </div>
                            }
                            <div className=' mt-3'>
                                <span  className='content heading_color_1'>Requested on :</span> <span className="content">{moment(each?.createdAt).format('DD/MM/YYYY hh:mm a')}</span>
                            </div>
                            { each?.data?.event !== "stay"  && 
                                <div className=' mt-3'>
                                    <span  className='content heading_color_1'>Booking Requested for : </span> 
                                    <ol  className=''>
                                        {/* {
                                            each?.data?.requestDates?.map((eachDate) => {
                                                return ( */}
                                                <li className="mt-2 content "> 
                                                    Date :  {moment(each?.data?.startDate).format('DD/MM/YYYY')} <br/>
                                                    Start Time :  {each?.data?.startTime} <br/>
                                                    {each?.data?.event === 'event' && `No. of Hours :  ${each?.data?.noOfHours}`} <br/>
                                                </li>
                                                {/* )
                                            } )
                                        } */}
                                    </ol>
                            </div>
                        }
                        {/* end::Text */}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
    const getModelBody = (key) => {
        switch (key) {
            case "Contact Host":
                return contactHostDetails(enquiryData);
            case "FindMySpot":
                return FindMySpotDetails(enquiryData);
            case "Claim Property":
                return claimPropertyDetails(enquiryData);
            case "EventPlanning":
                return eventPlanningtDetails(enquiryData);
            case "Subscription":
                return subscriptionDetails(enquiryData);
            case "Report Incorrect Info":
                return reportIncorrectInfoDetails(enquiryData);
            case "Booking Request":
                return bookingDetails(enquiryData);
            case "Schedule Property Visit":
                return getScheduleVisitDetails(enquiryData);
            case "Get In Touch":
                return getInTouchDetails(enquiryData);
            case "Host Get In Touch":
                return hostGetInTouchDetails(enquiryData);
            default:
                return "N/A";
        }
    }
    return(
        <div>
            <Header />
            <div className="container-fluid p-0">
                <div className="enquires_row g-0">
                <div className="d-flex ">
                    <div className=" d-none d-lg-block w-200">
                    <LeftSideBar />
                    </div>
                    <div className="enquiries col-lg-10  staticPageHtml">
                    <form className="h-100 needs-validation" noValidate>
                        <h3 className="main_text heading_color_1 mb-2"> My Enquiries </h3>
                        <div className="m-0">
                        <div id="pills-tabContent">
                            <div  id="pills-booking" role="tabpanel" aria-labelledby="pills-booking-tab">
                            {init && (
                            <>
                                    <div>
                                        <input onKeyDown={handleKeyDown} placeholder="Search" type="text" class="enquires_search mb-2" id="cname" onChange={(e) => setSearchTerm(e?.target?.value) } />
                                    </div>
                                {data?.length === 0 ? (
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span className="sub_text">No Enquiries Found</span>
                                </div>
                                ) : (
                                    <>
                                    <Table>
                                        <Thead className="sub_text">
                                            <Tr>
                                                {COLUMNS?.map((column,i)=>{
                                                    return(
                                                        <Th key={i}>{column}</Th>
                                                    )
                                                })}
                                            </Tr>
                                        </Thead>
                                        <Tbody className="content">
                                            {rows?.map((row,i1)=>{
                                                return(
                                                    <Tr key={i1}>
                                                        {Object.keys(row)?.map((key)=>{
                                                            if(key !== 'action'){
                                                                return(
                                                                    (key !== "id" &&  <Td key={key} className = "text-center">{row?.[key]}</Td>)
                                                                )
                                                            } else {
                                                                return(
                                                                    <Td className = "d-flex flex-row justify-content-center align-items-center" style = {{height : "61px", borderBottom : "none"}} >{actions(row?.id)}</Td>
                                                                )
                                                            }
                                                        })}
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                        </Table>
                                        {showDetailsPopup && (
                                            <DetailsPopup
                                                show={showDetailsPopup}
                                                onHide={() => setShowDetailsPopup(false)}
                                                title="Details"
                                                data={getModelBody(enquiryData?.type)} 
                                            />
                                        )}
                                        <div className="mt-2 d-flex justify-content-center" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                                <Pagination page={page} count={pageCount} variant="outlined" shape="rounded" color="primary" onChange={handleChange} />
                                        </div>
                                    </>
                                )}
                            </>
                            )}
                            </div>
                        </div>
                        </div>
                        
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Enquiries;