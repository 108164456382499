import React, {useEffect, useState} from "react";
import { useNavigate, useLocation  } from 'react-router-dom';
import PropertyTile from "../../Components/PropertyTile/PropertyTile";
import LazyLoader from "../../Utils/LazyLoader";
import { getSearchListings,getSearchDropData } from "../../Apis/Api";
import { Pagination, Stack } from "@mui/material";
import "./Activity.css"
import { useAuth } from "../../Utils/Auth";
import SearchableSelect from "../../Inputs/Select";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import NoResults from '../../Assets/Images/Search/no_results_found.jpg';
import selectEvent from '../../Assets/Images/Search/select-event.webp'
const EVENT_TYPE = [
    {label : "Film Shootings", value : "film"},
    {label : "Tv Shootings", value : "tv"},
    {label : "Events", value : "event"},
    {label : "Staycations", value : "stay"},
]
 

const LISTINGSNUMBER = 20;
const Activity = () => {
    const {triggerLoader} = useAuth()
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split('/');
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    const [data, setData] = useState([]);
    const [cities, setCities] = useState([]);
    const [activities, setActivities] = useState([]);
    const [formData,setFormData] = useState({
        searchInput: '',
        event: segments?.[2] || '',
        activity: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ').toLowerCase().replace(/^\w/, char => char.toUpperCase()) : '',
        city: segments?.[4] ? segments?.[4]?.replaceAll('-', ' ') : '',
        location: "",
        area: '',
        amenities: [],
        policies: [],
        attendies: '',
        eventType : "",
        plateType : "",
    })
    const onChangeOptionEvent = async (name,value) => {
        try{
            const data = {
                ...formData,
                [name] : value,
                city : '',
                activity : '',
                location : '',
            }
            const resp = await getSearchDropData(value)
            setCities(resp?.data?.cities)
            setActivities(resp?.data?.activities)
            // setFormData(data)
            setPage(1)
            handleUrlChange(data)
            // getData(data)
        }catch(error){
            console.log(error)
        }
    }
    const onChangeOption = (name,value) => {
        const data = {
            ...formData,
            [name] : value,
        }
        setFormData(data)
        setPage(1)
        handleUrlChange(data)
        // getData(data)
    }
    const handleUrlChange = (data) => {
        let URL = '/locations'

        if(data.event){
            URL = URL + `/${data.event}`
        }
        if(data.activity){
            URL = URL + `/${data.activity.replaceAll(' ','-').toLowerCase()}`
        }else{
            URL = URL + '/'
        }
        if(data.city){
            URL = URL + `/${data.city.replaceAll(' ','-').toLowerCase()}`
        }
        navigate(URL,{ state: { formData: data }})
      }
    // pagination start
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    // pagination end
    const getData = async (formData) => {
        triggerLoader(true)
        try {
            const response = await getSearchListings(formData, page, LISTINGSNUMBER);
            setData(response?.data?.listings);
            setPagesCount(response?.data?.pagesCount);
        } catch (error) {
            console.log(error?.response?.data?.message);
        }
        triggerLoader(false)
    };
    useEffect(() => {
        if(formData?.event){
            getData(formData);
            getDropData(formData?.event)
        }
    }, []);
    useEffect(() => {
        if(formData?.event) getData(formData);
    }, [formData,page]);
    useEffect(()=>{
        setFormData({
            ...formData,
            event: segments?.[2] || '',
            activity: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ').toLowerCase().replace(/^\w/, char => char.toUpperCase()) : '',
            city: segments?.[4] ? segments?.[4]?.replaceAll('-', ' ') : '',
        })
    },[pathname])
    const getDropData = async (type) => {
        try{
            const resp = await getSearchDropData(type)
            setCities(resp?.data?.cities)
            setActivities(resp?.data?.activities)
        }catch(error){
            console.log(error)
        }
    }
    // On pagination change
    const onPageChange = (event, page) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });   
        setPage(page <= 0 ? 1 : page);
    }
    const  paginationRounded = () => {
        return (
            <Stack className="mt-2 mb-3" spacing={2}>
                <Pagination 
                    onChange={(event, page) => onPageChange(event, page)}  
                    count={pagesCount}
                    variant="outlined"
                    shape="rounded"
                    page = {page}
                    // siblingCount={0}
                    // boundaryCount={1}
                />
            </Stack>
        );
    }

    const listingsView = () => {
        return(
            <>
                <div className={`w-100 d-flex justify-content-start flex-wrap col-md-12 col-lg-12`}>
                    {/* Search Section Start */}
                    <div className='search_bar_section'>
                        <div className="d-flex justify-content-center align-items-center flex-wrap row w-100">
                            <div className=" d-lg-block col-lg-3 search_inputs">
                                <SearchableSelect
                                    label='Seeking Locations for'
                                    name='event'
                                    options={EVENT_TYPE}
                                    value={formData?.event || ""}
                                    onChange={onChangeOptionEvent}
                                    placeholder='Select Event'
                                    style={{zIndex : '12'}}
                                    hideClearButton={true}
                                />
                            </div>
                            <div className="d-lg-block col-lg-3 search_inputs">
                                <SearchableSelect
                                    label='Activity'
                                    name='activity'
                                    options={activities}
                                    value={formData?.activity || ""}
                                    onChange={onChangeOption}
                                    placeholder='Select activity'
                                    disabled={!formData?.event}
                                    style={{zIndex : '11'}}
                                />
                            </div>
                            <div className=" d-lg-block col-lg-3 search_inputs">
                                <SearchableSelect
                                    label='City'
                                    name='city'
                                    options={cities}
                                    value={formData?.city || ""}
                                    onChange={onChangeOption}
                                    placeholder='Select city'
                                    disabled={!formData?.event}
                                    style={{zIndex : '10'}}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Search Section End */}
                    {data?.length ? (
                        <>
                            <ul className={`d-flex justify-content-center flex-wrap sp_search_listings_container p-0`} >
                                {data?.map((eachProperty, index) =>  {
                                    return (
                                        <li className="mb-2">
                                            <PropertyTile data={eachProperty} key = {index} eventType = {formData?.event} eventSub={formData?.eventType} plateType={formData?.plateType}/>
                                        </li>
                                    )
                                })}
                            </ul>
                            {/* Property Tiles Section End */}
                            <div className="d-flex justify-content-center w-100">
                                {paginationRounded()}
                            </div>
                        </>
                    ) : (
                        <>
                            {!formData?.event ? (
                                <div className="w-100 d-flex flex-column justify-content-center align-items-center m-5"> 
                                    <h1 className="main_text text-center" > Event Selection Needed </h1>
                                    <span className="content">It looks like you haven't selected a specific event yet.</span>
                                    <span className="content">Selecting an event will help us provide the best possible results tailored to your needs. Please choose an event to proceed.</span>
                                    <img alt="no results found" src={selectEvent} style={{width : '100%',height : 'auto',maxHeight : '400px', maxWidth : '400px'}}/>
                                </div>
                            ) : (
                                <div className="w-100 d-flex flex-column justify-content-center align-items-center m-5"> 
                                <h1 className="main_text text-center" > No Locations Found </h1>
                                <span className="content">It seems we couldn't find any locations matching your search criteria.</span>
                                <span className="content">Don't worry, you can try adjusting your search terms and explore our locations.</span>
                                <img alt="no results found" src={NoResults} style={{width : '100%',height : 'auto',maxHeight : '400px', maxWidth : '400px'}}/>
                                </div>

                            )}
                        </>
                    )}
                </div>
            </>
        )
    }

    return (
        <div className='Spotlet' >
            <Header/>
            <div className="container-fluid" >
                <div className="row">
                {listingsView()}
                </div>
            </div>
            <LazyLoader Component={Footer}/>
        </div>
    );
}

export default Activity;