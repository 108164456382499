import React, {useEffect, useState} from "react";
import { useNavigate  } from 'react-router-dom';
import PropertyTile from "../../Components/PropertyTile/PropertyTile";
import LazyLoader from "../../Utils/LazyLoader";
import { getSearchListings,getSearchDropData } from "../../Apis/Api";
import { Pagination, Stack } from "@mui/material";
import "./Search.css"
import { useAuth } from "../../Utils/Auth";
import TextInput from "../../Inputs/TextInput";
import SearchableSelect from "../../Inputs/Select";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import PropertiesCarousal from "../../Components/PropertiesCarousal/PropertiesCarousal";
import SearchFilter from "../../Modals/SearchFilter";
import NoResults from '../../Assets/Images/Search/no_results_found.jpg';
import selectEvent from '../../Assets/Images/Search/select-event.webp'
const EVENT_TYPE = [
    {label : "Film Shootings", value : "film"},
    {label : "Tv Shootings", value : "tv"},
    {label : "Events", value : "event"},
    {label : "Staycations", value : "stay"},
]
 

const LISTINGSNUMBER = 20;
const Search = () => {
    const {triggerLoader} = useAuth()
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split('/');
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [cities, setCities] = useState([]);
    const [activities, setActivities] = useState([]);
    const [propTypes, setPropTypes] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [featuredListings, setFeaturedListings] = useState({
        listings : [],
        settings : {}   
    });
    const [showFIlter,setShowFIlter] = useState(false)
    const [dataFetched,setDataFetched] = useState(false)
    const [formData,setFormData] = useState({
        searchInput: '',
        event: segments?.[2] || '',
        city: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ') : '',
        activity: "",
        location: "",
        area: '',
        amenities: [],
        policies: [],
        attendies: '',
        eventType : "",
        plateType : "",
    })
    const [errors, setErrors] = useState({});
    const onChange = (e) => {
        const {name,value} = e.target
        const data = {
            ...formData,
            [name] : value,
        }
        setFormData(data)
        setPage(1)
    }
    const onClickCity = () => {
        if(!formData?.event){
            setErrors({
                ...errors,
                event : 'Choose event before selecting city'
            })
        }
    }
    const onChangeOptionEvent = async (name,value) => {
        try{
            const data = {
                ...formData,
                [name] : value,
                city : '',
                activity : '',
                location : '',
            }
            if(value){
                const resp = await getSearchDropData(value)
                setCities(resp?.data?.cities)
                setActivities(resp?.data?.activities)
                setPropTypes(resp?.data?.propTypes)
                setErrors({
                    ...errors,
                    event : ''
                })
            }else{
                setErrors({
                    ...errors,
                    event : 'Choose event before selecting city'
                })
            }
            // setFormData(data)
            setPage(1)
            handleUrlChange(data)
            // getData(data)
        }catch(error){
            console.log(error)
        }
    }
    const onChangeOption = (name,value) => {
        const data = {
            ...formData,
            [name] : value,
        }
        // setFormData(data)
        setPage(1)
        handleUrlChange(data)
        // getData(data)
    }
    const validateForm = () => {
        let newErrors = {
            // event : formData?.event ? '' : 'Choose location before selecting city'
        }
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                return false
            }
        }
        return true
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        if (!validateForm()) {
            return null
        }
        getData(formData)
        setPage(1)
    }
    const handleUrlChange = (data) => {
        let URL = '/search'

        if(data.event){
            URL = URL + `/${data.event}`
        }
        if(data.city){
            URL = URL + `/${data.city.replaceAll(' ','-').toLowerCase()}`
        }
        navigate(URL,{ state: { formData: data } })
      }
    const clearAll = () => {
        const data = {
            searchInput: '',
            event: '',
            city: '',
            activity: "",
            location: "",
            area: '',
            amenities: [],
            policies: [],
            attendies: '',
            eventType : "",
            plateType : "",
        }
        setFormData(data)
        setPage(1)
        setData([])
        navigate('/search',{ replace : true })
    }
    const onFilter = () => {
        setShowFIlter(!showFIlter)
    }
    const onFilterApply = () => {
        setShowFIlter(!showFIlter)
    }
    // pagination start
    const [page, setPage] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    // pagination end
    const getData = async (formData) => {
        triggerLoader(true)
        try {
            const response = await getSearchListings(formData, page, LISTINGSNUMBER);
            setData(response?.data?.listings);
            setPagesCount(response?.data?.pagesCount);
            if (page === 1 && (featuredListings?.listings?.length === 0)) setFeaturedListings(response?.data?.featuredListings); // not to change state unnecessarily
        } catch (error) {
            console.log(error?.response?.data?.message);
        }
        triggerLoader(false)
    };
    useEffect(() => {
        if(formData?.event){
            getData(formData);
            getDropData(formData?.event)
        }
        setDataFetched(true)
    }, []);
    useEffect(() => {
        if(formData?.event && dataFetched){
            getData(formData)
        }else{
            setData({})
        }
        // const timer = setTimeout(() => {
        // }, 500);
        // return () => clearTimeout(timer)
    }, [formData,page]);
    useEffect(()=>{
        setFormData({
            ...formData,
            event: segments?.[2] || '',
            city: segments?.[3] ? segments?.[3]?.replaceAll('-', ' ') : '',
        })
    },[pathname])
    const getDropData = async (type) => {
        try{
            const resp = await getSearchDropData(type)
            setCities(resp?.data?.cities)
            setActivities(resp?.data?.activities)
            setPropTypes(resp?.data?.propTypes)
        }catch(error){
            console.log(error)
        }
    }
    // On pagination change
    const onPageChange = (event, page) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });   
        setPage(page <= 0 ? 1 : page);
    }
    const  paginationRounded = () => {
        return (
            <Stack className="mt-2 mb-3" spacing={2}>
                <Pagination 
                    onChange={(event, page) => onPageChange(event, page)}  
                    count={pagesCount}
                    variant="outlined"
                    shape="rounded"
                    page = {page}
                    // siblingCount={0}
                    // boundaryCount={1}
                />
            </Stack>
        );
    }


    const listingsView = () => {
        return(
            <div className={`w-100 d-flex justify-content-start flex-wrap col-md-12 ${showMap ? "col-lg-8" : "col-lg-12"}`}>
                {/* Search Section Start */}
                <div className='search_bar_section'>
                    <form className="d-flex justify-content-center align-items-center row w-100" onSubmit={onSubmit}>
                        <div className="col-lg-10">
                            <TextInput
                                id='search'
                                name='searchInput'
                                value={formData?.searchInput || ""}
                                onChange={onChange}
                                placeholder='Area/Property/Activity'
                            />
                        </div>
                        {/* <div className="col-lg-2">
                            <button className="sp_button btn btn-primary w-100 search_button_margin1" type="submit">Search</button>
                        </div> */}
                        {/* <div className="col-lg-2">
                            <button type="button" className={`btn ${!showMap ? "btn-primary" : "btn-secondary"} sp_button d-none d-lg-block w-100 search_button_margin1`} onClick = {() => setShowMap(!showMap) } > MAP </button>
                        </div> */}
                    </form>
                    <div className="d-flex justify-content-center align-items-center row w-100">
                        <div className="d-none d-lg-block col-lg-3">
                            <SearchableSelect
                                label='Seeking Locations for'
                                name='event'
                                options={EVENT_TYPE}
                                value={formData?.event || ""}
                                onChange={onChangeOptionEvent}
                                placeholder='Select Event'
                                errorMessage={errors?.event}
                                hideClearButton={true}
                            />
                        </div>
                        <div className="d-none d-lg-block col-lg-3" onClick={onClickCity}>
                            <SearchableSelect
                                label='City'
                                name='city'
                                options={cities}
                                value={formData?.city || ""}
                                onChange={onChangeOption}
                                placeholder='Select city'
                                disabled={!formData?.event}
                            />
                        </div>
                        <div className="col-lg-2">
                            <button type="button" onClick={onFilter} className="sp_button btn btn-primary w-100 search_button_margin2">Filters</button>
                        </div>
                        <div className="col-lg-2">
                            <button className="sp_button btn btn-secondary w-100 search_button_margin2" onClick={clearAll}>Clear All</button>
                        </div>
                    </div>
                </div>
                {/* Search Section End */}
                {/* Property Tiles Section Start */}
                {data?.length ? (
                    <>
                        {/* Featured Properties Start */}
                        {page === 1 && !showMap && <PropertiesCarousal data = {featuredListings?.listings} settings={featuredListings?.settings}  /> }
                        {/* Featured Properties End */}
                        <ul className={`d-flex justify-content-center flex-wrap sp_search_listings_container p-0`} >
                            {data?.map((eachProperty, index) =>  {
                                return (
                                    <li className="mb-2">
                                        <PropertyTile data={eachProperty} key = {index} eventType = {formData?.event} eventSub={formData?.eventType} plateType={formData?.plateType}/>
                                    </li>
                                )
                            })}
                        </ul>
                        {/* Property Tiles Section End */}
                        <div className="d-flex justify-content-center w-100">
                            {paginationRounded()}
                        </div>
                    </>
                ) : (
                    <>
                        {!formData?.event ? (
                            <div className="w-100 d-flex flex-column justify-content-center align-items-center m-5"> 
                                <h1 className="main_text text-center" > Event Selection Needed </h1>
                                <span className="content">It looks like you haven't selected a specific event yet.</span>
                                <span className="content">Selecting an event will help us provide the best possible results tailored to your needs. Please choose an event to proceed.</span>
                                <img alt="no results found" src={selectEvent} style={{width : '100%',height : 'auto',maxHeight : '400px', maxWidth : '400px'}}/>
                            </div>
                        ) : (
                            <div className="w-100 d-flex flex-column justify-content-center align-items-center m-5"> 
                            <h1 className="main_text text-center" > No Locations Found </h1>
                            <span className="content">It seems we couldn't find any locations matching your search criteria.</span>
                            <span className="content">Don't worry, you can try adjusting your search terms and explore our locations.</span>
                            <img alt="no results found" src={NoResults} style={{width : '100%',height : 'auto',maxHeight : '400px', maxWidth : '400px'}}/>
                            </div>

                        )}
                    </>
                )}
            </div>
        )
    }

    const mapView = () => {
        return(
            <div className={`${showMap ? "d-none d-lg-block col-lg-4 sp_search_map_section" : "d-none"}`} >
                {/* MAP SECTION */}
                <iframe 
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.841237541376!2d78.35066457581152!3d17.41940510188071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb94763220bd63%3A0x394f5bd30e7a09e2!2sAparna%20Green%20Homes!5e0!3m2!1sen!2sin!4v1723010426549!5m2!1sen!2sin&zoom=15&disableDefaultUI=true&scrollwheel=false&disableDoubleClickZoom=true" 
                width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        )
    }
    const onClearFilter = (data) => {
        setFormData(data)
        // getData(data)
        setShowFIlter(false)
    }
    const onSubmitFilter = (data) => {
        setFormData(data)
        // getData(data)
        setPage(1)
        setShowFIlter(false)
    }
    return (
        <div className='Spotlet' >
            <Header/>
            <div className="container-fluid" >
                <div className="row">
                    {listingsView()}
                    {mapView()}
                </div>
            </div>
            {showFIlter &&
                <SearchFilter
                    show={showFIlter}
                    formData={formData}
                    onHide={onFilter}
                    onSuccess={onFilterApply}
                    onClear={onClearFilter}
                    onSubmit={onSubmitFilter}
                    onChangeOptionEvent={onChangeOptionEvent}
                    activities={activities}
                    propTypes={propTypes}
                    cities={cities}
                />
            }
            <LazyLoader Component={Footer}/>
        </div>
    );
}

export default Search;