import Modal from 'react-bootstrap/Modal';
import "./ModalWrapper.css"
import CloseIcon from '@mui/icons-material/Close';
const ModalWrapper = ({ heading,props,size,children }) => {
    return(
        <Modal
            {...props}
            size={size || "md"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={props?.backdrop || ""}
        >
            <Modal.Body>
                <div className='sp_modal_head'>
                    <span className='main_text heading_color_1'>{heading}</span>
                    <div className='cursor' onClick={props.onHide}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className='sp_modal_body'>
                    {children}
                </div>
            </Modal.Body>
        </Modal>
        
    )
}
export default ModalWrapper;