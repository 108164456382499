import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import "./Auth.css"
import { Link } from "react-router-dom";
import logo from "../../Assets/Images/Auth/spotlet_logo.webp"
import Autheniticator from '../../Components/Autheniticator/Autheniticator';
import { useUser } from '../../Utils/Auth';

const Auth = () => {
  const location = useLocation();
  const {user} = useUser()
  const navigate = useNavigate()
  const onSuccess = () => {
    navigate('/')
  }
  useEffect(()=>{
    if(user){
      navigate('/')
    }
  },[user]);

  return (
    <div className='Spotlet'>
      <div
      id='auth_main_section'
      className='auth_main_section'
      >
        <div id='auth_brand_section' className='auth_brand_section auth_mobile_hide'>
          <Link href="" className="heading_color_1 sub_text m-2 link"to={'/'} target="_blank">
            <img alt="spotlet logo" className='auth_logo' src={logo}/>
          </Link>
        </div>
        <div className='auth_content_section'>
          <div className='authenticator_main'>
            <Autheniticator activeTab={location?.state?.tab} onSuccess={onSuccess}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;